var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-filter")]),_c('div',_vm._l((_vm.betPoints.concat(_vm.pointList.datas)),function(item,index){return _c('v-chip',{key:index,staticClass:"ma-1 chip",attrs:{"color":"#F7BC28","label":"","text-color":"white"},on:{"click":function($event){return _vm.onSelectFilter(item, index)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(item == 0 ? "ทั้งหมด" : item)+" ("+_vm._s(_vm.roomlength(item))+") ")],1)}),1)],1),_c('div',{staticClass:"divider div-transparent"}),_vm._l((_vm.filterRoom),function(room,roomIndex){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterRoom.length > 0),expression:"filterRoom.length > 0"}],key:roomIndex,attrs:{"cols":"12","lg":"6"}},[_c('v-col',{staticClass:"room-card"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"player-info d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"#3481AE"}},[_vm._v("mdi-account-circle")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(room.p1))])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"#3481AE"}},[_vm._v("mdi-cash")]),_c('span',[_vm._v(_vm._s(room.amount))])],1)]),_c('v-divider',{staticClass:"mt-1"})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('div',{staticClass:"\n                  room-info\n                  d-flex\n                  flex-column\n                  justify-space-between\n                  align-center\n                "},[_c('div',{staticClass:"d-flex justify-center align-center flex-column",staticStyle:{"width":"100%","height":"100%"}},[(
                      _vm.$vuetify.breakpoint.name == 'lg' ||
                      _vm.$vuetify.breakpoint.name == 'xl'
                    )?_c('div',[_vm._v(" เลือกเพื่อต่อสู้ ")]):_vm._e(),(
                      _vm.$vuetify.breakpoint.name == 'lg' ||
                      _vm.$vuetify.breakpoint.name == 'xl'
                    )?_c('v-icon',[_vm._v("mdi-arrow-right-bold ")]):_vm._e()],1),_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[(
                      _vm.$vuetify.breakpoint.name == 'xs' ||
                      _vm.$vuetify.breakpoint.name == 'sm' ||
                      _vm.$vuetify.breakpoint.name == 'md'
                    )?_c('div',[_vm._v(" เลือกเพื่อต่อสู้ ")]):_vm._e()])])]),_vm._l((room.rpsSelect),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4","lg":"3"}},[_c('div',{staticClass:"text-centers",class:item.active ? 'option-active' : 'option',on:{"click":function($event){return _vm.onSelectOption(
                    roomIndex,
                    index,
                    item.value,
                    room.roomId,
                    room.amount,
                    item.src
                  )}}},[_c('v-img',{staticClass:"img",attrs:{"src":item.src,"contain":""}}),_c('div',[_vm._v(" "+_vm._s(item.name)),(room.p1bet == item.value)?_c('v-icon',{staticClass:"pl-1",attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e()],1)],1)])}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"room-footer d-flex justify-space-between"},[_c('div',[_vm._v("ห้องที่ #"+_vm._s(room.roomId))]),_c('div',{staticClass:"\n                    d-flex\n                    align-center\n                    animate__animated animate__fadeIn animate__infinite\n                    infinite\n                    animate__slow\n                  "},[_c('v-icon',{staticClass:"\n                      pr-1\n                      animate__animated animate__rotateIn animate__infinite\n                      infinite\n                    ",staticStyle:{"font-size":"1.3em"}},[_vm._v("mdi-timer-sand")]),_vm._v(" "+_vm._s(room.status == "waiting" ? "รอคู่ต่อสู้" : "")+" ")],1),(
                    _vm.$vuetify.breakpoint.name == 'lg' ||
                    _vm.$vuetify.breakpoint.name == 'xl'
                  )?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"pr-1",staticStyle:{"font-size":"1.3em"}},[_vm._v("mdi-clock-time-five-outline")]),_vm._v(" "+_vm._s(_vm.moment(room.createdAt).format("DD/MM/YYYY HH:mm"))+" ")],1):_vm._e()])])],2)],1)],1)}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterRoom.length <= 0),expression:"filterRoom.length <= 0"}],staticClass:"text-center",staticStyle:{"color":"white"},attrs:{"cols":"12"}},[_c('div',[_vm._v("ไม่พบห้อง")]),_c('v-btn',{staticClass:"mt-2 btn-create",staticStyle:{"color":"white"},on:{"click":function($event){return _vm.$emit('onCreateGame')}}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-plus-circle")]),_vm._v(" สร้างห้อง")],1)],1)],2),_c('confrmDialog',{attrs:{"nameBet":_vm.nameBet,"amount":_vm.amount,"confirmImg":_vm.src,"dialog":_vm.dialogConfrm,"txtMessage":"ยืนยืนการทำรายการ ?"},on:{"closeAlertDialog":function($event){return _vm.closeFrmDialog($event)}}}),_c('alertDialog',{attrs:{"dialog":_vm.dialog,"txtMessage":_vm.txtMessage},on:{"closeAlertDialog":function($event){return _vm.closeAlertDialog($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-col
    v-if="
      $vuetify.breakpoint.xl || $vuetify.breakpoint.lg || $vuetify.breakpoint.md
    "
    class="justify-lg-space-between justify-md-space-between pt-16 pl-16 pr-16"
  >
    <div
      class="d-flex justify-space-between navbar-active"
      v-if="userProfile.length != 0"
    >
      <router-link to="/" class="top-menu"
        ><div class="px-2">หน้าแรก</div>
      </router-link>
      <router-link to="/game" class="top-menu"
        ><div class="px-2">เล่นเกม</div>
      </router-link>
      <router-link to="/promotion" class="top-menu"
        ><div class="px-2">โปรโมชั่น</div>
      </router-link>
      <router-link to="/cashback" class="top-menu"
        ><div class="px-2">รับเครดิตเงินคืน</div>
      </router-link>
      <router-link to="/invite" class="top-menu"
        ><div class="px-2">หารายได้เสริม</div>
      </router-link>
      <router-link to="/ranking" class="top-menu"
        ><div class="px-2">รางวัลประจำเดือน</div>
      </router-link>
      <router-link to="/blog" class="top-menu"
        ><div class="px-2">บทความ</div>
      </router-link>
      <a class="top-menu" :href="LineSetting" target="_blank"> ติดต่อเรา </a>
    </div>
    <div v-else class="d-flex justify-space-between navbar-active">
      <router-link to="/" class="top-menu"
        ><div class="px-2">หน้าแรก</div>
      </router-link>
      <router-link to="/game" class="top-menu"
        ><div class="px-2">เล่นเกม</div>
      </router-link>
      <router-link to="/promotion" class="top-menu"
        ><div class="px-2">โปรโมชั่น</div>
      </router-link>
      <router-link to="/ranking" class="top-menu"
        ><div class="px-2">รางวัลประจำเดือน</div>
      </router-link>
      <router-link to="/blog" class="top-menu"
        ><div class="px-2">บทความ</div>
      </router-link>
      <a class="top-menu" :href="LineSetting" target="_blank"> ติดต่อเรา </a>
    </div>
  </v-col>

  <div v-else>
    <div>
      <v-app-bar style="background-color: #ea848400" dense fixed elevation="0">
        <v-row class="tab-custom pb-0 px-4">
          <v-col cols="3" class="pl-0 d-flex justify-start pb-0">
            <v-app-bar-nav-icon
              color="white"
              large
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon
          ></v-col>
          <v-col
            v-if="userProfile.length != 0"
            class="d-flex justify-end align-center pb-0"
          >
            <div>
              <v-icon size="25" style="color: gold">mdi-cash</v-icon>
            </div>
            <div style="color: gold; font-size: 18px">
              {{ userBalance.balance }}
            </div>
            <div class="pl-1" style="color: white; font-size: 15px">
              ({{ userBalance.currentTurnover }}/{{
                userBalance.targetTurnover
              }})
            </div>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-navigation-drawer color="#31302F" v-model="drawer" fixed temporary>
        <div v-if="userProfile.length != 0">
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="white--text text--accent-4"
            >
              <v-list-item link @click="$router.push({ path: '/' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-home-circle</v-icon
                  ><span class="white--text">หน้าแรก</span></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/game' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-play-circle</v-icon
                  ><span class="white--text">เล่นเกม</span></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/promotion' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-tag-outline</v-icon
                  ><span class="white--text">โปรโมชั่น</span></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/cashback' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-cash</v-icon
                  ><span class="white--text"
                    >รับเครดิตเงินคืน</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/invite' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-cash-multiple</v-icon
                  ><span class="white--text"
                    >หารายได้เสริม</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/ranking' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-trophy</v-icon
                  ><span class="white--text"
                    >รางวัลประจำเดือน</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/blog' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white"
                    >mdi-text-box-multiple-outline</v-icon
                  ><span class="white--text">บทความ</span></v-list-item-title
                >
              </v-list-item>

              <v-list-item :href="LineSetting" target="_blank">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-account-box</v-icon
                  ><span class="white--text">ติดต่อเรา</span></v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-else>
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="white--text text--accent-4"
            >
              <v-list-item link @click="$router.push({ path: '/' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-home-circle</v-icon
                  ><span class="white--text">หน้าแรก</span></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/game' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-play-circle</v-icon
                  ><span class="white--text">เล่นเกม</span></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/promotion' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-tag-outline</v-icon
                  ><span class="white--text">โปรโมชั่น</span></v-list-item-title
                >
              </v-list-item>

              <v-list-item link @click="$router.push({ path: '/ranking' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-trophy</v-icon
                  ><span class="white--text"
                    >รางวัลประจำเดือน</span
                  ></v-list-item-title
                >
              </v-list-item>
              <v-list-item link @click="$router.push({ path: '/blog' })">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white"
                    >mdi-text-box-multiple-outline</v-icon
                  ><span class="white--text">บทความ</span></v-list-item-title
                >
              </v-list-item>

              <v-list-item :href="LineSetting" target="_blank">
                <v-list-item-title class="text-start align-center"
                  ><v-icon class="mr-3" color="white">mdi-account-box</v-icon
                  ><span class="white--text">ติดต่อเรา</span></v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userBalance: (state) => state.user.userBalance,
      userProfile: (state) => state.user.userProfile,
      LineSetting: (state) => state.setting.LineSetting,
    }),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    curentPath: 0,
    data_for_list_logout: [
      {
        text: "หน้าแรก",
        icon: "mdi-home-circle",
        link: "/",
      },
      {
        text: "เล่นเกม",
        icon: "mdi-play-circle",
        link: "/game",
      },
      {
        text: "โปรโมชั่น",
        icon: "mdi-tag-outline",
        link: "/promotion",
      },
      {
        text: "รางวัลประจำเดือน",
        icon: "mdi-trophy",
        link: "/ranking",
      },
      {
        text: "บทความ",
        icon: "mdi-text-box-multiple-outline",
        link: "/blog",
      },
      {
        text: "ติดต่อเรา",
        icon: "mdi-account-box",
        link: "/contact",
      },
    ],
    data_for_list_login: [
      {
        text: "หน้าแรก",
        icon: "mdi-home-circle",
        link: "/",
      },
      {
        text: "เล่นเกม",
        icon: "mdi-play-circle",
        link: "/game",
      },
      {
        text: "โปรโมชั่น",
        icon: "mdi-tag-outline",
        link: "/promotion",
      },
      {
        text: "รับเครดิตเงินคืน",
        icon: "mdi-cash",
        link: "/cashback",
      },
      {
        text: "หารายได้เสริม",
        icon: "mdi-cash-multiple",
        link: "/invite",
      },
      {
        text: "รางวัลประจำเดือน",
        icon: "mdi-trophy",
        link: "/ranking",
      },

      {
        text: "บทความ",
        icon: "mdi-text-box-multiple-outline",
        link: "/blog",
      },
      {
        text: "ติดต่อเรา",
        icon: "mdi-account-box",
        link: "/contact",
      },
    ],
  }),
  methods: {
    changePath(item) {
      //  console.log(item, "ssss");
      this.curentPath = item;
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 961px) and (max-width: 1024px) {
  .top-menu {
    font-size: 17px !important;
  }
}
.top-menu {
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.border-bottom-custom {
  height: 4px;
}

.navbar-active .router-link-exact-active {
  border-bottom: 3px solid goldenrod;
}

.tab-custom {
  background: rgb(0, 0, 0, 0.5);
}
</style>
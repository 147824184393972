<template>
  <div>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="loginDialog"
      @click:outside="closeDialog()"
      :fullscreen="true"
    >
      <div
        class="card-loading-screen d-flex align-center justify-center flex-column"
      >
        <v-img
          src="../assets/BIRDBET88.webp"
          lazy-src="../assets/BIRDBET88.webp"
          contain
        ></v-img>
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
        <h3 class="mt-3" style="letter-spacing: 2px">
          กำลังเชื่อมต่อเซิฟเวอร์เกม...
        </h3>
        <h3
          v-if="txtMessage"
          style="color: yellow; letter-spacing: 2px"
          class="mt-2 text-center"
        >
          <div>เกิดข้อผิดพลาด</div>
          <div>{{ this.txtMessage }}</div>
        </h3>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["loginDialog", "txtMessage"],
};
</script>
moute
<style scoped>
.text-gold {
  font-size: 19px;
  font-weight: bold;
  background: -moz-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;

}
.btn_block {
  text-decoration: none;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(247, 173, 45, 1) 0%,
    rgba(214, 185, 15, 1) 100%
  );
  color: black;
  width: 150px;
}
.btn_block {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(247, 173, 45, 1) 0%,
    rgba(214, 185, 15, 1) 100%
  );
  color: black;
}
.card-loading-screen {
  border: solid 2px #f7c52d;
  background: rgb(49, 4, 4);
  border-radius: 5px;
  height: 100%;
  padding: 100px 10px;
}
</style>
<template>
  <v-row justify="center">
    <v-dialog
      max-width="600"
      @click:outside="onCancelConfirmDialog()"
      v-model="onShowDialog"
    >
      <v-card class="border-card">
        <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
          <v-btn
            @click="onCancelConfirmDialog()"
            class="ma-2"
            outlined
            x-small
            fab
            color="red"
          >
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </div>
        <v-card-title class="headline justify-center">
          <v-row class="justify-center">
            <v-col cols="12" class="pa-0 align-center d-flex justify-center">
              <v-icon size="65" color="yellow">mdi-alert-circle-outline</v-icon>
            </v-col>
            <v-col
              style="color: gold"
              cols="12"
              class="pb-0 pt-0 d-flex flex-column justify-center align-center"
              >{{ dataConfirmDialog.title }}</v-col
            >
          </v-row>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="pr-1">
                <v-btn
                  width="100%"
                  class="btn-signin mt-5"
                  depressed
                  @click="onCancelConfirmDialog(true)"
                >
                  <div class="btn-login-text">ตกลง</div>
                </v-btn>
              </v-col>
              <v-col class="pl-1">
                <v-btn
                  width="100%"
                  class="btn-cancel mt-5"
                  depressed
                  @click="onCancelConfirmDialog(false)"
                >
                  <div class="btn-login-text">ยกเลิก</div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["onShowDialog", "dataConfirmDialog"],
  data: () => ({}),
  methods: {
    onCancelConfirmDialog(accept) {
      this.$emit("onCancelConfirmDialog", { isOpen: false, onAccept: accept });
    },
  },
};
</script>


<style scoped>
.border-card {
  border-radius: 8px !important;

  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1) !important;
  background-color: #321a04 !important;
}
.text-gold {
  font-size: 19px;
  font-weight: bold;
  background: -moz-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
}

.content-text {
  color: rgb(255, 0, 0);
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
.btn-cancel {
  border-radius: 8px;
  background: linear-gradient(90deg, #ff0800 0%, #b64726 100%);
}
</style>

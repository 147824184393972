<template>
  <div class="black">
    <v-container>
      <v-col
        ><v-row
          ><v-col
            ><div class="form-text-header text-center">BBGAME88</div>
            <div class="form-text-detail text-center">
              เราคือบริษัทแม่จากประเทศฟิลิปปินส์ บริหารและดำเนินงานทั้งหมด
              <br />หมดปัญหาถูกหลอก เล่นตรง จ่ายตรง มั่นคง 100% บริหาร sagame
              คาสิโนออนไลน์<br />มีระบบแนะนำเพื่อนรับคำแนะนำสูงสุด 0.7%
              ประกอบด้วย คาสิโนต่างๆ มากมาย ได้แก่ บาคาร่า<br />{{
                $vuetify.breakpoint.xs
                  ? ""
                  : "เสือมังกร รูเล็ตกำกั่ว และเกมสล็อตออนไลน์ เยอะและมากที่สุด"
              }}
            </div></v-col
          ></v-row
        >
        <v-row justify="center">
          <v-col cols="12" md="3" xl="2" lg="2" sm="4">
            <v-btn
              :height="$vuetify.breakpoint.xl ? '80px' : '48px'"
              class="btn-login"
              block
              depressed
              @click="goToLine()"
            >
              <img
                img
                src="../../assets/line.png"
                :width="$vuetify.breakpoint.xl ? '40px' : '20px'"
              />
              <div class="btn-login-text pl-2">@bbgame88</div>
            </v-btn>
          </v-col>
          <v-col
            v-if="userProfile.length == 0"
            cols="12"
            md="3"
            xl="2"
            lg="2"
            sm="4"
          >
            <v-btn
              :height="$vuetify.breakpoint.xl ? '80px' : '48px'"
              class="btn-login"
              block
              depressed
              @click.stop="showDialog = true"
            >
              <img
                img
                src="../../assets/add-user.png"
                :width="$vuetify.breakpoint.xl ? '40px' : '20px'"
              />
              <div class="btn-login-text pl-3">สมัครสมาชิก</div>
            </v-btn>
            <RegisterDialog
              :dialog="showDialog"
              @onCancelDialog="onCancelDialog($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
    <v-container :class="$vuetify.breakpoint.xs ? 'pr-0' : ''">
      <v-row class="justify-center ma-0">
        <v-col
          @click="$router.push({ path: '/game' })"
          :style="$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : ''"
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
        >
          <v-img
            class="cursor-hover swing"
            src="../../assets/pic-1.png"
          ></v-img>
        </v-col>
        <v-col
          @click="$router.push({ path: '/game' })"
          :style="$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : ''"
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
        >
          <v-img class="cursor-hover swing" src="../../assets/pic2.png"></v-img>
        </v-col>
      </v-row>
      <v-row class="justify-center ma-0">
        <v-col
          @click="$router.push({ path: '/game' })"
          :style="$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : ''"
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
        >
          <v-img class="cursor-hover swing" src="../../assets/pic3.png"></v-img>
        </v-col>
        <v-col
          @click="$router.push({ path: '/game' })"
          :style="$vuetify.breakpoint.xs ? 'transform: scale(0.95)' : ''"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : ''"
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
        >
          <v-img class="cursor-hover swing" src="../../assets/pic4.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row no-gutters align="center" justify="center">
        <v-col
          class="d-flex align-center"
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
        >
          <v-col align="start">
            <div class="form-text-header">ข้อดีที่สุดของ BBGAME88</div>
            <div
              v-for="(item, index) in data_advantages"
              :key="index"
              class="form-text-detail d-flex align-center"
            >
              <img
                class="size-icon-correc pr-1"
                src="../../assets/icon-correc.png"
                width="40px"
              />
              {{ item.text }}
            </div>
          </v-col>
        </v-col>
        <v-col
          xl="5"
          lg="5"
          md="7"
          sm="8"
          cols="10"
          class="pl-2 pb-1 d-flex flex-column justify-center align-center"
        >
          <v-img
            transition="slide-x-transition"
            class="thumbnail swing"
            src="../../assets/pic5.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pb-16 px-lx-16 px-lg-16">
      <SectionGamePlay />
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SectionGamePlay from "../../components/s_content_game";
import RegisterDialog from "../../components/RegisterDialog";
export default {
  components: {
    SectionGamePlay,
    RegisterDialog,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      LineSetting: (state) => state.setting.LineSetting,
    }),
  },
  methods: {
    goToLine() {
      window.location = this.LineSetting;
    },
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
      console.log("OnCancelDialog", onCancelDialog);
    },
  },
  data: () => ({
    showDialog: false,
    data_advantages: [
      {
        text: "ฝาก ถอน อัตโนมัติ 10 วินาที",
      },
      {
        text: "เล่นตรงกับผู้ให้บริการทั้งหมด ไม่ผ่านเอเย่นต์",
      },
      {
        text: "ฝาก ถอน ไม่จำกัดจำนวนครั้ง และจำนวนเงิน",
      },
      {
        text: "  แทงเดิมพันสูงสุด 500,000 บาท / ไม้",
      },
      {
        text: "  สมัครง่าย ไม่ต้องแอดไลน์ ไม่ต้องจำยูส",
      },
      {
        text: "แอดมินดูแลอย่างมืออาชีพ ตลอด 24 ชั่วโมง",
      },
      {
        text: "  โปรโมชั่นรายวัน โปรโมชั่นรายเดือน ดีสุดใน 3 โลก",
      },
    ],
    data_game: [
      {
        title: "game1",
        gameTag: "sa",
        img: require("../../assets/sa.098389ff.png"),
      },
      {
        title: "game2",
        gameTag: "wm",
        img: require("../../assets/wm.cec99433.png"),
      },
      {
        title: "game3",
        gameTag: "dg",
        img: require("../../assets/dg.4290471b.png"),
      },
      {
        title: "game4",
        gameTag: "sexy",

        img: require("../../assets/sexy.6941d11a.png"),
      },
      {
        title: "game5",
        gameTag: "amb",
        img: require("../../assets/amb.6819f11b.png"),
      },
      {
        title: "game6",
        gameTag: "sp",

        img: require("../../assets/sp.d56e503f.png"),
      },
      {
        title: "game7",
        gameTag: "pretty",
        img: require("../../assets/pretty.9b07a22c.png"),
      },
      {
        title: "game8",
        gameTag: "pg2",
        subgame: true,
        img: require("../../assets/pg.01cc9d85.png"),
      },
      {
        title: "game9",
        gameTag: "joker",
        subgame: true,

        img: require("../../assets/joker.ae7b74d2.png"),
      },
      {
        title: "game10",
        gameTag: "hotg",
        img: require("../../assets/hotgraph.ff1d4e47.png"),
      },
      {
        title: "game11",
        gameTag: "ka",
        subgame: true,
        img: require("../../assets/ka.90dffedd.png"),
      },
    ],
  }),
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 1440px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1536px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1736px) {
  .form-text-header {
    font-size: 40px !important;
  }
  .form-text-detail {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1160px) {
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 961px) {
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
  .text-slot9th {
    margin-top: -180px;
  }

  .form-text-header {
    font-size: 25px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-icon-correc {
    width: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-icon-size {
    font-size: 20px !important;
  }
  .btn-login-text {
    font-size: 0.9em !important;
    font-weight: 400 !important;
    text-transform: lowercase !important;
  }
  .login-box {
    padding: 0px 0px 0px 0px !important;
    /* margin-top: -385px !important; */
    width: 100% !important;
  }
  .resize-logo-section {
    /* margin-top: -70px !important; */
    padding-top: 0px !important;
  }
  .resize-logo-img {
    padding-top: 40px;
    padding-top: 60px !important;
    width: 100% !important;
  }
  .text-slot9th {
  }

  .form-text-header {
    font-size: 24px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-icon-correc {
    width: 20px !important;
  }
  .btn-line-regis {
    width: 120px !important;
  }
}
@media only screen and (max-width: 375px) {
  .header {
    height: 635px !important;
    background-position-y: 255px !important;
  }
}

.btn-login {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100% 100%
  );
}
.btn-login-text {
  font-size: 1.5em;
  text-transform: lowercase !important;
  letter-spacing: 0.2px;
}
.form-icon-style {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(232, 232, 216, 1) 8%,
    rgba(236, 231, 217, 1) 11%,
    rgba(187, 179, 165, 1) 91%
  );
}
.resize-logo-img {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}
.resize-logo-section {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}

.form-text-header {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}
.form-text-detail {
  font-size: 28px;
  color: #fff;
}
.size-icon-correc {
  width: 40px;
}
.btn-line-regis {
  width: 300px;
}
.cursor-hover:hover {
  cursor: pointer;
  opacity: 0.7;
}
.img-hover-zoom {
  height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.5);
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 3deg);
    transform: rotate3d(0, 0, 1, 3deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2.5deg);
    transform: rotate3d(0, 0, 1, 2.5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 3deg);
    transform: rotate3d(0, 0, 1, 3deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2.5deg);
    transform: rotate3d(0, 0, 1, 2.5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  position: relative;
}

.swing:hover {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-duration: 1s;
}
</style>

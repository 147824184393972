<template>
  <div class="black pb-12">
    <v-container class="pb-16 px-lx-16 px-lg-16">
      <div class="text-demo text-center"><h2>โหมดทดลองเล่น</h2></div>
      <div class="divider-custom" style="height: 2px"></div>
      <SectionGamePlay/>
    </v-container>

  </div>
</template>
<script>
import SectionGamePlay from '../../components/s_content_game'
export default {
  data: () => ({}),
  components:{
    SectionGamePlay
  }
};
</script>
<style scoped>
.text-demo {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
</style>
import config from "../../config/config";
import { globalAxios } from "../../../axios-config";
const state = () => ({});

const mutations = {};

const actions = {
  async getPromotion({ commit }, data) {
   
    try {
      let response = await globalAxios({
        method: "post",
        url: `/${config.api.deposit}/bonus/${data.bonusId}`,
      });
      if (response != null) {
        return { msg: true, response: response.data};
      }
    } catch (error) {
     // console.log("error", error);
      return { msg: false, response: error.response.data };
    }
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<template>
  <div>
    <v-row justify="center">
      <v-dialog
        scrollable
        :max-width="$vuetify.breakpoint.name == 'xs' ? '100%' : '600px'"
        @click:outside="onCancelNewsDialog()"
        v-model="onShowNewsDialog"
      >
        <v-card class="border-card">
          <v-row class="justify-center">
            <v-col class="d-flex justify-center" cols="3">
              <v-img
                class="main-logo"
                contain
                src="../assets/BIRDBET88.webp"
                width="50% 
                  "
              >
              </v-img
            ></v-col>
          </v-row>
          <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
            <v-btn
              @click="onCancelNewsDialog()"
              class="ma-2"
              outlined
              x-small
              fab
              color="red"
            >
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </div>
          <v-card-title class="headline justify-center">
            <v-row class="justify-center">
              <v-col
                style="color: gold"
                cols="12"
                class="pb-0 d-flex justify-center"
                ><v-icon class="pr-2" size="30px" style="color: gold"
                  >mdi-bullhorn-outline</v-icon
                >
                <div class="text-title ">ประกาศ</div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-container>
              <v-card-text
                style="height: 400px; border-radius: 5px; color: white"
                class="text-center"
              >
                <v-row>
                  <v-col cols="12">
                    <div>
                      <b> <h2>{{ dataNews.title }}</h2> </b>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div v-html="dataNews.content" class="mt-1"></div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                <v-checkbox
                  v-model="isShow"
                  dense
                  hide-details
                  dark
                  color="white"
                  label="ไม่แสดงหน้านี้อีก"
                ></v-checkbox
              ></v-col>
              <v-col cols="12">
                <v-btn
                  @click="onCancelNewsDialog()"
                  width="100%"
                  class="btn-signin"
                  depressed
                >
                  <div class="btn-login-text pl-3" style="color: white">
                    <v-icon small class="pr-1">mdi-check-all</v-icon>
                    ตกลง
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    isShow: false,
  }),
  props: ["onShowNewsDialog", "dataNews"],
  methods: {
    onCancelNewsDialog() {
      let cloneItem = this.dataNews;
      if (this.isShow == true) {
        //  console.log(cloneItem)
        //  console.log('xxxxxxxxxxxxxx')
        this.$emit("onCancelNewsDialog", cloneItem);
        this.isShow = false;
      } else {
        //    console.log('sssssssssss')
        this.$emit("onCancelNewsDialog");
      }
    },
  },
};
</script>
<style scoped>
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.text-title {
  font-size: 25px;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1) !important;
  background-color: #321a04 !important;
}

.content-text {
  color: rgb(255, 0, 0);
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>


<template>
  <div>
    <v-bottom-navigation
      :value="value"
      grow
      background-color="#3F2105"
      class="d-flex justify-space-around"
      fixed
    >
      <v-btn link @click="$router.push({ path: '/' })">
        <span style="color: white">หน้าแรก</span>

        <v-icon color="white">mdi-home-circle</v-icon>
      </v-btn>

      <v-btn
        v-if="userProfile.length != 0"
      
        @click="$router.push({ path: '/transaction' })"
      >
        <span style="color: white">ฝาก - ถอน</span>
        <v-icon color="white">mdi-cash-multiple</v-icon>
      </v-btn>

      <v-btn link @click="$router.push({ path: '/game' })">
        <span style="color: white">เล่นเกม</span>

        <v-icon color="white">mdi-play-circle</v-icon>
      </v-btn>
      <v-btn link @click="goToLine()">
        <span style="color: white">ติดต่อเรา</span>

        <v-icon color="white">mdi-email</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <TransactionDialog
      :dialog="showDialog"
      @onCancelDialog="onCancelDialog($event)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransactionDialog from "./TransactionDialog";
export default {
  computed: {
    ...mapState({
        LineSetting: (state) => state.setting.LineSetting,
      userProfile: (state) => state.user.userProfile,
    }),
  },
  data: () => ({ value: 1, showDialog: false }),
  components: { TransactionDialog },
  methods: {
     goToLine() {
      window.location = this.LineSetting;
    },
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
     // console.log("OnCancelDialog", onCancelDialog);
    },
  },
};
</script>

<style scoped>
</style>
export default {
  devMode: true,
  baseURL: "https://bbgame88.co",
  lineURL: "http://line.me/ti/p/~@bbgame88",
  gaCode: "G-WCY20HWQXH",
  api: {
    user: `user`,
    game: `game`,
    withdraw: `withdraw`,
    ranking: `ranking`,
    transfer: `transfer`,
    deposit: `deposit`,
    promotion: `promotion`,
    invite: `invite`,
    referer: `referer`,
    setting: `setting`,
    annouce: `announce`,
    minigame: `minigame`
  },
  dev: {
    api: 'https://api.bbgame88.co',
    api_invite:'https://bbgame88.co'
   // api: 'https://api.bbgame88.co'
    // api: 'http://localhost:1200'

    //api: "https://test-web-api.flexbox.gg",
  },
  prod: {
    api: "https://api.bbgame88.co",
    api_invite:'https://bbgame88.co'
  },
};


<template>
  <div class="padding-content">
    <v-container>
      <v-row>
        <v-col cols="12" class="my-2" style="border-radius: 8px">
          <v-card>
            <v-tabs
              v-model="tab"
              background-color="lack accent-4"
              centered
              center-active
              dark
              icons-and-text
              color="#F7BC28"
            >
              <v-tabs-slider color="#F7BC28"></v-tabs-slider>
              <v-tab>
                เล่นเกม({{ roomList.length }})
                <v-icon>mdi-gamepad-variant-outline</v-icon>
              </v-tab>
              <v-tab>
                สร้างเกม
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-tab>
              <v-tab>
                ผลแพ้ชนะ
                <v-icon>mdi-list-status</v-icon>
              </v-tab>
              <!-- <v-tab>
                อันดับ
                <v-icon>mdi-trophy-variant-outline</v-icon>
              </v-tab> -->
            </v-tabs>
            <v-tabs-items class="border-card" v-model="tab">
              <v-tab-item
                ><playgame @onCreateGame="tab = 1" :ischangeTab="ischangeTab" />
              </v-tab-item>
              <v-tab-item><creategame /> </v-tab-item>
              <v-tab-item><result /> </v-tab-item>
              <!-- <v-tab-item><ranking /> </v-tab-item> -->
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import playgame from "../../components/game/minigame/rps/tab_play.vue";
import creategame from "../../components/game/minigame/rps/tab_create_game";
import result from "../../components/game/minigame/rps/tab_result";
import ranking from "../../components/game/minigame/rps/tab_ranking";
import { mapState } from "vuex";
export default {
  components: { playgame, creategame, result, ranking },
  data() {
    return {
      ischangeTab: null,
      timerhistory: null,
      timer: null,
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  watch: {
    tab() {
      this.ischangeTab = this.tab;
    },
  },
  computed: {
    ...mapState({
      roomList: (state) => state.game.roomListRps,
    }),
  },
  methods: {
    loopGetRoom() {
      clearInterval(this.timer);
      this.timer = setInterval(async () => {
        let res = await this.$store.dispatch("game/getRoomListRps");
        if (!res) {
          return false;
        }
      }, 1000);
      window.isGettingRoom = true;
    },
    loopGetHistory() {
      clearInterval(this.timerhistory);
      this.timerhistory = setInterval(async () => {
        let res = await this.$store.dispatch("game/getHistoryRPS");
        if (!res) {
          return false;
        }
      }, 3000);
      window.isGettingHistory = true;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("game/getSettingTypeRPS");
    let res = await this.$store.dispatch("game/getRoomListRps");
    if (!res.message) {
      if (!window.isGettingRoom || !window.isGettingHistory) {
        this.loopGetRoom();
        this.loopGetHistory();
      }
    } else {
      console.log("error", res);
    }
  },
};
</script>
<style scoped>
.main-bg {
  margin: 10px;
  border-radius: 8px;
}
.border-card {
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;

  background-color: #321a04 !important;
}
.padding-content {
  padding-bottom: 100px;
}
</style>
<template>
  <div>
    <v-dialog
   
      max-width="700px"
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
      @click:outside="onCancelDialog()"
      v-model="dialog"
    >
      <div>
        <v-card
          :style="
            $vuetify.breakpoint.name != 'xs' ? 'height: 120vh' : 'height:100vh'
          "
          :class="$vuetify.breakpoint.xs ? 'border-card-xs' : 'border-card '"
        >
          <v-row class="justify-center">
            <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
              <v-btn
                @click="onCancelDialog()"
                class="ma-2"
                outlined
                x-small
                fab
                color="red"
              >
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </div>
            <v-col
              cols="12"
              class="pb-0 d-flex flex-column justify-center text-center"
              ><h3 class="text-title">ฝาก - ถอน</h3></v-col
            >
          </v-row>

          <v-container
            class="px-0 pb-0 px-sm-0 pb-sm-0 px-lg-0 pb-lg-0 px-xl-0 pb-xl-0"
          >
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="black accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                  ฝาก
                  <v-icon>mdi-bank-outline</v-icon>
                </v-tab>

                <v-tab>
                  ถอน
                  <v-icon>mdi-cash-multiple</v-icon>
                </v-tab>

                <v-tab>
                  ประวัติ
                  <v-icon>mdi-history</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <TabDeposit />
                </v-tab-item>
                <v-tab-item>
                  <TabWithDraw />
                </v-tab-item>
                <v-tab-item>
                  <TabHistory />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-container>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import TabDeposit from "./tab_deposit.vue";
import TabWithDraw from "./tab_withdraw.vue";
import TabHistory from "./tab_history_transaction.vue";

export default {
  data: () => ({
    tab: null,
  }),

  props: ["dialog"],
  components: {
    TabDeposit,
    TabWithDraw,
    TabHistory,
  },
  methods: {
    formatAsCurrency(value, dec) {
      dec = dec || 0;
      if (value === null) {
        return 0;
      }
      return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    onCancelDialog() {
      this.$emit("onCancelDialog", false);
      // for (let i = 0; i < this.withdrawData.length; i++) {
      //   this.withdrawData[i].value = false;
      //   this.withDrawActiveText = false;
      // }
    },
  },
};
</script>
<style scoped>
.text-title {
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card-xs {
  padding: 1rem;
  color: white !important;
  background-color: #321a04 !important;
}
.border-card {
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
.border-card-bank1 {
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #d1d1d1 0% 65%,
    #b3b3b3 95% 100%
  );
}
.border-card-bank2 {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-radius: 8px;

  background-image: linear-gradient(
    to bottom right,
    #202020 0% 65%,
    #141414 95% 100%
  );
}
.border-card-bank-user {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-color: #ffc107;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #000000 0% 65%,
    #141414 95% 100%
  );
}
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>
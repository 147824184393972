import { globalAxios } from "../../../axios-config";
import config from "../../config/config";
const state = () => ({
  gameList: [],
  allGame: [],
  betPoint: [],
  rpsHistory: [],
  roomListRps: []
});

const mutations = {
  setGameSubList(state, payload) {
    state.gameList = payload;
    // state.gameList.unshift({
    //   gameId: "rps",
    //   image: "uploads/1629786481491.png",
    //   maintenance: 0,
    //   maintenanceText: "",
    //   showIndex: 1,
    //   subGame: 0,
    //   title: "เกมเป่ายิ่งฉุบ",
    // }); //ไว้เทส
  },
  resetSubGameList(state) {
    state.gameList = [];
  },
  setAllGame(state, payload) {
    state.allGame = payload;
  },
  SET_BET_POINT(state, payload) {
    state.betPoint = payload;
  },
  SET_RPS_HISTORY(state, payload) {
    state.rpsHistory = payload;
  },
  SET_ROOM_LIST(state, payload) {
    state.roomListRps = payload.map((v) => {
      return {
        ...v,
        rpsSelect: [
          {
            name: "ค้อน",
            src: "/hand-rock.png",
            active: false,
            value: "r",
          },
          {
            name: "กระดาษ",
            src: "/hand-paper.png",
            active: false,
            value: "p",
          },
          {
            name: "กรรไกร",
            src: "/hand-scissors.png",
            active: false,
            value: "s",
          },
        ],
      };
    });
  },
};

const actions = {
  async getGameSubList({ commit }, type) {
  //  console.log(type);
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.game}/${type}`,
      });
    //  console.log("dd");
      if (response != null) {
        commit("setGameSubList", response.data.games);
      }
      return true;
    } catch (error) {
    //  console.log("error", error);
      return error.response.data.errors;
    }
  },
  async getGameURL({ commit }, data) {
    try {
      let response = await globalAxios({
        method: "post",
        url: `/${config.api.game}/${data.gameTag}/${data.gameType}`,
        data: data.body,
      });
      if (response != null) {
        return { status: true, response: response.data.url };
      }
    } catch (error) {
     // console.log("error", error);
      return { status: false, response: error.response.data };
    }
  },

  async getGame({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.game}`,
      });
      commit("setAllGame", response.data.datas);
      return response.data.datas;
    } catch (error) {
    //  console.log("error", error);
      return error.response.data
    }
  },
  async postJoinRoom({ commit }, data) {
    const url = `${config.api.minigame}/rps/${data.roomId}`;
    delete data.roomId;
    try {
      let response = await this.$axios.$post(url, data);
      return { status: true, response: response };
    } catch (error) {
      return { status: false, response: error.response.data };
    }
  },
  async getRoomListRps({ commit }, data) {
    const url = `${config.api.minigame}/rps`;
    try {
      let response = await this.$axios.$get(url, data);
      commit("SET_ROOM_LIST", response.datas);
      return response;
    } catch (error) {
      return error.response.data;
    }
  },
  async postRoomRPS({ commit }, data) {
    const url = `${config.api.minigame}/rps`;
    try {
      let response = await this.$axios.$post(url, data);
      return { status: true, response: response };
    } catch (error) {
      return { status: false, response: error.response.data };
    }
  },
  async getSettingTypeRPS({ commit }, data) {
    const url = `${config.api.minigame}/rps/roomtype`;
    
    try {
      let response = await this.$axios.$get(url);
     
      commit("SET_BET_POINT", response);
      return response;
    } catch (error) {
    
      return false;
    }
  },
  async getHistoryRPS({ commit }, data) {
    const url = `${config.api.minigame}/rps/history`;
    try {
      let response = await this.$axios.$get(url);
      commit("SET_RPS_HISTORY", response.data);
      return response.data;
    } catch (error) {
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

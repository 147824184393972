<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="12" class="d-flex align-center justify-end">
          <v-icon color="white">mdi-filter</v-icon>
          <div>
            <v-chip
              class="ma-1 chip"
              color="#F7BC28"
              label
              text-color="white"
              @click="onSelectFilter(item, index)"
              v-for="(item, index) in betPoints.concat(pointList.datas)"
              :key="index"
            >
              <v-icon left> mdi-cash </v-icon>
              {{ item == 0 ? "ทั้งหมด" : item }}
              ({{ roomlength(item) }})
            </v-chip>
          </div>
        </v-col>
        <div class="divider div-transparent"></div>
        <v-col
          cols="12"
          lg="6"
          v-show="filterRoom.length > 0"
          v-for="(room, roomIndex) in filterRoom"
          :key="roomIndex"
        >
          <v-col class="room-card">
            <v-row>
              <v-col cols="12">
                <div
                  class="player-info d-flex align-center justify-space-between"
                >
                  <div class="d-flex align-center">
                    <v-icon color="#3481AE">mdi-account-circle</v-icon>
                    <span class="pl-1">{{ room.p1 }}</span>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon color="#3481AE">mdi-cash</v-icon>
                    <span>{{ room.amount }}</span>
                  </div>
                </div>
                <v-divider class="mt-1"></v-divider>
              </v-col>
              <v-col cols="12" lg="3">
                <div
                  class="
                    room-info
                    d-flex
                    flex-column
                    justify-space-between
                    align-center
                  "
                >
                  <div
                    class="d-flex justify-center align-center flex-column"
                    style="width: 100%; height: 100%"
                  >
                    <div
                      v-if="
                        $vuetify.breakpoint.name == 'lg' ||
                        $vuetify.breakpoint.name == 'xl'
                      "
                    >
                      เลือกเพื่อต่อสู้
                    </div>
                    <v-icon
                      v-if="
                        $vuetify.breakpoint.name == 'lg' ||
                        $vuetify.breakpoint.name == 'xl'
                      "
                      >mdi-arrow-right-bold
                    </v-icon>
                  </div>
                  <div class="text-center" style="width: 100%">
                    <div
                      v-if="
                        $vuetify.breakpoint.name == 'xs' ||
                        $vuetify.breakpoint.name == 'sm' ||
                        $vuetify.breakpoint.name == 'md'
                      "
                    >
                      เลือกเพื่อต่อสู้
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="4"
                lg="3"
                v-for="(item, index) in room.rpsSelect"
                :key="index"
              >
                <div
                  class="text-centers"
                  :class="item.active ? 'option-active' : 'option'"
                  @click="
                    onSelectOption(
                      roomIndex,
                      index,
                      item.value,
                      room.roomId,
                      room.amount,
                      item.src
                    )
                  "
                >
                  <v-img class="img" :src="item.src" contain></v-img>
                  <div>
                    {{ item.name
                    }}<v-icon
                      v-if="room.p1bet == item.value"
                      class="pl-1"
                      color="green"
                      >mdi-check-circle</v-icon
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="room-footer d-flex justify-space-between">
                  <div>ห้องที่ #{{ room.roomId }}</div>
                  <div
                    class="
                      d-flex
                      align-center
                      animate__animated animate__fadeIn animate__infinite
                      infinite
                      animate__slow
                    "
                  >
                    <v-icon
                      style="font-size: 1.3em"
                      class="
                        pr-1
                        animate__animated animate__rotateIn animate__infinite
                        infinite
                      "
                      >mdi-timer-sand</v-icon
                    >

                    {{ room.status == "waiting" ? "รอคู่ต่อสู้" : "" }}
                  </div>
                  <div
                    class="d-flex align-center"
                    v-if="
                      $vuetify.breakpoint.name == 'lg' ||
                      $vuetify.breakpoint.name == 'xl'
                    "
                  >
                    <v-icon style="font-size: 1.3em" class="pr-1"
                      >mdi-clock-time-five-outline</v-icon
                    >
                    {{ moment(room.createdAt).format("DD/MM/YYYY HH:mm") }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <v-col
          style="color: white"
          cols="12"
          v-show="filterRoom.length <= 0"
          class="text-center"
        >
          <div>ไม่พบห้อง</div>
          <v-btn
            style="color: white"
            class="mt-2 btn-create"
            @click="$emit('onCreateGame')"
          >
            <v-icon class="pr-1" small>mdi-plus-circle</v-icon>
            สร้างห้อง</v-btn
          >
        </v-col>
      </v-row>
      <confrmDialog
        :nameBet="nameBet"
        :amount="amount"
        :confirmImg="src"
        :dialog="dialogConfrm"
        txtMessage="ยืนยืนการทำรายการ ?"
        @closeAlertDialog="closeFrmDialog($event)"
      />
      <alertDialog
        :dialog="dialog"
        :txtMessage="txtMessage"
        @closeAlertDialog="closeAlertDialog($event)"
      />
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import alertDialog from "../../../alertDialog/alertDialog.vue";
import confrmDialog from "../../../alertDialog/game/minigame/rps/confirmDialogSelectRPS.vue";
import moment from "moment";
export default {
  components: {
    confrmDialog,
    alertDialog,
  },
  props: ["ischangeTab"],
  mounted() {
    this.onSelectFilter(0, 0);
  },
  watch: {
    ischangeTab() {
      this.onSelectFilter(0, 0);
    },
  },
  computed: {
    filterRoom() {
      if (this.amountFilter) {
        return this.roomList.filter((v) => v.amount == this.amountFilter);
      } else if (this.amountFilter == 0) {
        return this.roomList;
      } else {
        return this.roomList;
      }
    },

    ...mapState({
      roomList: (state) => state.game.roomListRps,
      pointList: (state) => state.game.betPoint,
      user: (state) => state.authen.user,
    }),
  },
  methods: {
    roomlength(item) {
      if (item == 0) {
        return this.roomList.length;
      }
      let result = this.roomList.filter((v) => v.amount == item);
      return result.length;
    },
    moment,
    onSelectFilter(item, index) {
      let element = document.getElementsByClassName("chip");
      element.forEach((e) => {
        e.classList.remove("chip-active");
      });
      element[index].classList.add("chip-active");
      this.amountFilter = item;
    },
    closeAlertDialog() {
      this.dialog = false;
    },
    async closeFrmDialog(isConfrim) {
      this.dialogConfrm = false;

      let body = {
        roomId: this.roomId,
        p2bet: this.currentChoice,
      };
      if (isConfrim) {
        let res = await this.$store.dispatch("game/postJoinRoom", body);
        if (res) {
          this.dialog = true;
          this.txtMessage = res.response.message;
        }
      }
    },
    async onSelectOption(roomIndex, index, val, roomId, amount, src) {
      if (!this.user) {
        this.dialog = true;
        this.txtMessage = "กรุณาเข้าสู่ระบบก่อนทำรายการ";
        return false;
      }
      this.nameBet =
        val == "r"
          ? "ค้อน"
          : val == "p"
          ? "กระดาษ"
          : val == "s"
          ? "กรรไกร"
          : "";
      this.currentChoice = val;
      this.amount = amount;
      this.src = src;
      this.roomId = roomId;
      this.dialogConfrm = true;
      // this.roomList[roomIndex].rpsSelect.map((v) => (v.active = false));
      // this.roomList[roomIndex].rpsSelect[index].active = true;
      // let confrm = confirm("Confirm");
    },
  },
  data() {
    return {
      betPoints: [0],
      amountFilter: null,
      nameBet: null,
      amount: 0,
      src: null,
      dialog: false,
      txtMessage: null,
      roomId: null,
      currentChoice: null,
      dialogConfrm: false,
      optionActive: null,
      rpsSelect: [
        {
          name: "ค้อน",
          src: "/hand-rock.png",
          active: false,
          value: "r",
          css: "bg-rock d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
          cssActive:
            "bg-rock d-flex flex-column align-center justify-center cursor_pointer rps-select",
        },
        {
          name: "กระดาษ",
          src: "/hand-paper.png",
          active: false,
          value: "p",
          css: "bg-paper d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
          cssActive:
            "bg-paper d-flex flex-column align-center justify-center cursor_pointer rps-select",
        },
        {
          name: "กรรไกร",
          src: "/hand-scissors.png",
          active: false,
          value: "s",
          css: "bg-scissors d-flex flex-column align-center justify-center cursor_pointer rps-unselect",
          cssActive:
            "bg-scissors d-flex flex-column align-center justify-center cursor_pointer rps-select",
        },
      ],
    };
  },
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(247, 188, 40),
    transparent
  );
}
.divider {
  width: 100%;
  position: relative;
  height: 1px;
}
.chip-active {
  background: white !important;
  color: #010a19 !important;
}
.player-info {
  width: 100%;
}
.room-info {
  height: 100%;
}

.room-footer {
  background: #0a1833;
  border-radius: 3px;
  font-weight: 300;
  padding: 2px 5px;
  font-size: 14px;
}
.room-card {
  background: #010a19;
  border-radius: 5px;
}
.option:hover > .img {
  transform: rotate(45deg);
}
.option-active {
  background: white;
  color: black;
  border: 2px solid white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}
.img {
  transition: 0.5s;
}
.option {
  background: #0a1833;
  color: white;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}
.btn-create {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
</style>

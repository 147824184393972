<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      :fullscreen="$vuetify.breakpoint.xs ? true : false"
      max-width="95%"
      @click:outside="onCancelDialog()"
      v-model="dialog"
    >
      <v-card class="border-card">
        <v-card-title class="headline justify-center">
          <v-row class="justify-center">
            <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
              <v-btn
                @click="onCancelDialog()"
                class="ma-2"
                outlined
                x-small
                fab
                color="red"
              >
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </div>
            <v-col
              cols="12"
              class="d-flex flex-column justify-center align-center"
              ><h3 class="text-title">เลือกเกมพารวย</h3></v-col
            >
          </v-row></v-card-title
        >

        <v-card-text>
          <v-container>
            <div>
              <v-row class="pa-3">
                <v-col
                  v-for="(item, index) in gameList"
                  :key="index"
                  xs="3"
                  sm="3"
                  md="3"
                  lg="2"
                  cols="4"
                  class="px-2"
                >
                  <div @click="onSelectGame(item)" class="container-custom">
                    <div>
                      <v-img
                        @mouseover="playSound()"
                        class="image"
                        :lazy-src="item.image"
                        :src="item.image"
                      ></v-img>
                    </div>
                    <div
                      class="text-center"
                      style="color: white; font-size: 17px"
                    >
                      {{ item.gameName }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      gameList: (state) => state.game.gameList,
    }),
  },
  props: ["dialog"],
  methods: {
    playSound() {
      var audio = new Audio(
        require("../assets/mixkit-soap-bubble-sound-2925.wav")
      );
      audio.play();
    },
    onCancelDialog() {
      this.$emit("onCancelDialog", false);
    },
    onSelectGame(game) {
     // console.log(game);
      this.$emit("onSelectGame", game);
      this.onCancelDialog();
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .text-title {
    font-size: 23px !important;
  }
}
.border-card {
  border-radius: 20px;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
.text-title {
  font-size: 25px;
  font-weight: bold;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container-custom:hover .image {
  opacity: 0.6;
  transform: scale(1.1);
}

.container-custom:hover .middle {
  opacity: 1;
}
.container-custom {
  position: relative;
  width: 100%;
}
.image {
  cursor: pointer;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  text-align: center;
}
</style>
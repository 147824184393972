<template>
  <div>
    <v-row
      ><v-col>
        <v-container>
          <div class="text-start text-promotion-header">
            ระบบถอนเงินอัตโนมัติ
          </div>
          <div class="text-center">
            จำนวนเครดิตทั้งหมด:฿{{ userbalance.balance }}
            <div class="border-card-bank2 mt-3 mb-5">
              <v-row class="px-3 py-2 justify-center">
                <v-col
                  @click="changeActive(index)"
                  v-for="(item, index) in withdrawData"
                  :key="index"
                  cols="6"
                >
                  <v-btn
                    :class="
                      item.value == true
                        ? 'withdraw-btn-active pa-1'
                        : 'withdraw-btn-unactive pa-1'
                    "
                    block
                    depressed
                  >
                    {{ item.text }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="activeSpecify == true" class="justify-center">
                <v-col cols="11">
                  <div class="text-start">ระบุจำนวนเครดิต:</div>
                  <div>
                    <v-text-field
                      @input="checkActiveWithDraw"
                      v-model="price"
                      prepend-inner-icon="mdi-cash-multiple"
                      suffix="฿"
                      dark
                      color="green"
                      type="number"
                      label="จำนวนที่ต้องการถอน"
                      :rules="rules"
                      hide-details="auto"
                    ></v-text-field>
                    <div class="text-start mt-3">
                      ถอนเงินขั้นต่ำ ฿300 สูงสุดไม่เกิน ฿2,000,000 ต่อครั้ง
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-btn 
                @click="activeWithDraw(price)"
                :class="
                  withDrawActiveText == true
                    ? 'withdraw-btn-active mt-3'
                    : 'withdraw-btn-unactive mt-3'
                "
                block
                depresseds
              >
                ถอน
              </v-btn>
              <v-divider
                class="mt-6 mb-3"
                style="background-color: #ffc107"
              ></v-divider>
              <div>
                <div class="d-flex pt-3">
                  <v-icon class="pr-1" style="color: white"
                    >mdi-format-list-bulleted-square</v-icon
                  >
                  <h4 style="color: white">ข้อจำกัด & ประกาศ</h4>
                </div>
                <ul
                  class="pt-1 text-start"
                  v-for="(item, index) in conditionWithdrawData"
                  :key="index"
                >
                  <li style="font-size: 15px">{{ item.text }}</li>
                </ul>
              </div>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <ErrorDialog
      :dataError="dataError"
      :onShowErrorDialog="showDialog"
      @onCancelDialog="onCancelErrorDialog($event)"
    />
    <ConfirmDialog
      :dataConfirmDialog="dataConfirmDialog"
      :onShowDialog="showDialogConfirm"
      @onCancelConfirmDialog="onCancelConfirmDialog($event)"
    />
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import ErrorDialog from "./ErrorDialog.vue";
import { mapState } from "vuex";
export default {
  components: {
    ConfirmDialog,
    ErrorDialog,
  },
  data: () => ({
    showDialog: false,
    showDialogConfirm: false,
    dataConfirmDialog: { title: "ยืนยันการถอนใช่หรือไม่?" },
    dataError: {},
    rules: [
      (value) => !!value || "โปรดระบุจำนวนเครดิต.",
      (value) => (value && value.length >= 3) || "ขั้นต่ำ ฿300",
    ],
    withDrawActive: false,
    withDrawActiveText: false,
    activeSpecify: false,
    price: null,
    withdrawData: [
      {
        text: "฿300",
        value: false,
        amount: "300",
      },
      {
        text: "฿500",
        value: false,
        amount: "500",
      },
      { text: "฿1,000", value: false, amount: "1000" },
      {
        text: "฿1,500",
        value: false,
        amount: "1500",
      },
      {
        text: "฿3,000",
        value: false,
        amount: "3000",
      },
      {
        text: "฿5,000",
        value: false,
        amount: "5000",
      },
      { text: "฿10,000", value: false, amount: "10000" },
      {
        text: "เลือกจำนวนเครดิต",
        value: false,
        specify: 0,
      },
    ],
    conditionWithdrawData: [
      {
        text: "ครั้งละไม่เกิน 2,000,000 บาท",
      },
      {
        text: "ถอนเงินขั้นต่ำ 300 บาทขึ้นไป",
      },
    ],
  }),
  computed: {
    ...mapState({
      userbalance: (state) => state.user.userBalance,
      userProfile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialog = onCancelErrorDialog;
   //   console.log("onCancelErrorDialog", onCancelErrorDialog);
      
    },

    async onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.loadingwithdraw = true;
        let body = {
          amount: this.price,
        };
       // console.log("body amount", body);
        let res = await this.$store.dispatch("transection/withDraw", body);
        if (res.msg) {
          this.showDialog = true;
          this.dataError = res.data;
        } else {
          this.showDialog = true;
          this.dataError = res.data;
        }
        this.loadingwithdraw = false;
      }
    },
    changeActive(index) {
    //  console.log(index);
      for (let i = 0; i < this.withdrawData.length; i++) {
        this.withdrawData[i].value = false;
        this.withDrawActiveText = true;
      }
      this.withdrawData[index].value = true;
      this.price = this.withdrawData[index].amount;
    //  console.log(this.price);

      if (this.withdrawData[index].specify == 0) {
        this.activeSpecify = true;
        this.withDrawActiveText = false;
      //  console.log("true");
      } else {
        this.activeSpecify = false;
      }
    },
    checkActiveWithDraw() {
   //   console.log(this.price);
      if (this.price.length >= 3) {
        this.withDrawActiveText = true;
      } else {
        this.withDrawActiveText = false;
      }
    },
    async activeWithDraw(amount) {
    //  console.log(amount);
      if (amount == null) {
        this.showDialog = true;
        this.dataError = { message: "โปรดระบุจำนวนเงินที่ต้องการถอน" };
      } else {
        this.showDialogConfirm = true;
      }
    },
      onCancelDialog() {
      this.$emit("onCancelDialog", false);
      for (let i = 0; i < this.withdrawData.length; i++) {
        this.withdrawData[i].value = false;
        this.withDrawActiveText = false;
      }
    },
  },
};
</script>

<style scoped>
.withdraw-btn-active {
  background: radial-gradient(
    circle,
    rgba(246, 197, 45, 1) 0%,
    rgba(128, 94, 23, 1) 100%
  );
}
.withdraw-btn-unactive {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(139, 139, 139, 1) 100%
  );
}
.text-title {
  font-size: 22px;
  font-weight: bold;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card-xs {
  padding: 1rem;
  color: white !important;
  background-color: #321a04 !important;
}
.border-card {
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
.border-card-bank1 {
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #d1d1d1 0% 65%,
    #b3b3b3 95% 100%
  );
}
.border-card-bank2 {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-radius: 8px;

  background-image: linear-gradient(
    to bottom right,
    #202020 0% 65%,
    #141414 95% 100%
  );
}
.border-card-bank-user {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-color: #ffc107;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    #000000 0% 65%,
    #141414 95% 100%
  );
}
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>
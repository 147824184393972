<template>
  <div>
    <Home />
    <Transaction
      :dialog="showDialog"
      @onCancelDialog="onCancelDialog($event)"
    />
  </div>
</template>
<script>
import Home from "../home/Home";
import Transaction from "../../components/TransactionDialog";
export default {
  components: {
    Home,
    Transaction,
  },
  data: () => ({
    showDialog: false,
  }),
  methods: {
    async getAllTranSaction() {
      if (localStorage.getItem("toKen") != null) {
        await this.$store.dispatch("transection/userGetHisoryWithdraw");
        await this.$store.dispatch("transection/getDeposit");
      //  console.log("getalltransaction");
        setTimeout(() => this.getAllTranSaction(), 3000);
      }
    },
    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
      this.$router.push({ path: "/" });
  //    console.log("OnCancelDialog", onCancelDialog);
    },
  },

  
  async mounted() {
    this.getAllTranSaction();
    this.showDialog = true;
  },
};
</script>
<style scoped>
</style>

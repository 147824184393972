<template>
  <v-app class="my_app">
    <!-- <Snow /> -->
    <Header />
    <vue-page-transition name="fade-in-right">
      <router-view></router-view>
    </vue-page-transition>
    <!-- <Footer /> -->
    <BottomNavigator />
    <v-btn
      :class="
        $vuetify.breakpoint.xs
          ? 'mb-10 transition-swing'
          : 'transition-swing mb-3'
      "
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      bottom
      right
      color="gold"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up-thick</v-icon>
    </v-btn>
    <NewsDialog
      v-if="newsItems"
      :dataNews="newsItems"
      :onShowNewsDialog="showNewsDialog"
      @onCancelNewsDialog="onCancelNewsDialog($event)"
    />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import NewsDialog from "./components/NewsDialog.vue";
import Snow from "./components/Snow";
import Header from "./components/Header";
import BottomNavigator from "./components/BottomNavigation";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      newsList: (state) => state.news.newsList,
    }),
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    onCancelNewsDialog(payload) {
      this.showNewsDialog = false;
      if (payload) {
        this.$store.commit("news/updateNewsList", payload);
      }
      setTimeout(() => {
        this.countIndex++;
        this.onShowNews();
      }, 300);
    },
    onShowNews() {
      let disableNewsList = localStorage.getItem("newsList");
      if (disableNewsList) {
        disableNewsList = JSON.parse(disableNewsList);
      }
      if (this.newsList.length != this.countIndex); // loop data
      let data = this.newsList[this.countIndex];
      if (data) {
        //check function
        if (disableNewsList && !disableNewsList.includes(data.id)) {
          this.newsItems = data;
          this.showNewsDialog = true;
        } else if (!disableNewsList) {
          this.newsItems = data;
          this.showNewsDialog = true;
        } else if (disableNewsList && disableNewsList.includes(data.id)) {
          this.countIndex++;
          this.onShowNews();
        }
        //end check function
      } //end loop data
    },
  },
  data: () => ({
    fab: false,
    showNewsDialog: false,
    countIndex: 0,
    newsItems: {},
  }),
  async mounted() {
    await this.$store.dispatch("news/getAnnounce");

    // this.$store.commit("news/setNewsList");
    if (
      this.$route.path == "/" ||
      this.$route.path == "/*" ||
      this.$route.path == "/ranking" ||
      this.$route.path == "/game" ||
      this.$route.path == "/promotion" ||
      this.$route.path == "/register" ||
      this.$route.path == "/blog" ||
      this.$route.path == "/invite" ||
      this.$route.path == "/cashback"
    ) {
      this.onShowNews();
    }
    this.$store.commit("user/setCurrentToken");
  },
  async beforeCreate() {
    await this.$store.dispatch("setting/getSetting");
  },
  created() {
    var min = 30;
    var now = new Date().getTime();
    var source = localStorage.getItem("source");
    if (source == null) {
      if (this.$route.query.s != null) {
        window.localStorage.setItem(
          "source",
          JSON.stringify({
            source: this.$route.query.s,
            expiredAt: now,
          })
        );
      }
    } else {
      if (source) {
        let data = JSON.parse(source);
        if (data.source != this.$route.query.s && this.$route.query.s != null) {
          window.localStorage.setItem(
            "source",
            JSON.stringify({
              source: this.$route.query.s,
              expiredAt: now,
            })
          );
        }
        if (new Date().getTime() - data.expiredAt > min * 60 * 1000) {
          localStorage.removeItem("source");
          window.localStorage.setItem(
            "source",
            JSON.stringify({
              source: this.$route.query.s,
              expiredAt: now,
            })
          );
          this.$store.commit("user/setSource");
        }
      }
    }
  },

  components: {
    Snow,
    Header,
    BottomNavigator,
    NewsDialog,
    Footer,
  },
};
</script>


<style>
::-webkit-scrollbar {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;500;700&display=swap");

* {
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 600px) {
  .v-label-custom .v-label {
    font-size: 15px !important ;
    color: #5b5d69;
    font-weight: 400;
  }
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.v-label-custom .v-label {
  font-size: 22px;
  color: #5b5d69;
  font-weight: 400;
}
.my_app {
  background: black !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
}

/* .v-label-custom-dialog .v-label {
  color: rgba(246, 197, 45, 1);
  font-weight: 400;
} */
</style>

<template>
  <div class="pb-12">
    <v-container class="pb-16 px-lx-16 px-lg-16">
      <div class="text-title text-center"><h2>โปรโมชั่น</h2></div>
      <div class="divider-custom" style="height: 2px"></div>
      <v-row class="mt-4 justify-center">
        <v-col
          v-for="(item, index) in dataPromotion"
          :key="index"
          cols="11"
          sm="10"
          md="10"
          xl="9"
          lg="9"
          class="ma-2 border-card"
        >
          <v-row>
            <v-col cols="12" lg="5" xl="5" md="5" sm="5" class="pa-2">
              <v-img
                :lazy-src="item.img"
                cover
                class="img-promotion"
                :src="item.img"
              ></v-img>
            </v-col>
            <v-col
              class="d-flex flex-column align-start justify-center"
              cols="12"
              lg="7"
              xl="7"
              md="7"
              sm="7"
            >
              <div style="color: gold">{{ item.title }}</div>
              <ul class="pb-4">
                <li
                  v-for="(item2, index) in item.detail"
                  :key="index"
                  class="text-promotion-detail text-start"
                >
                  {{ item2.text }}
                </li>
              </ul>
              <v-btn
                v-if="userProfile.length == 0"
                width="100%"
                class="btn-signin"
                depressed
              >
                <div class="btn-login-text pl-3">กรุณาเข้าสู่ระบบ</div>
              </v-btn>
              <v-btn
                @click="onGetPromotion(item, index)"
                v-else
                width="100%"
                class="btn-signin"
                depressed
              >
                <div class="btn-login-text pl-3">รับโปรโมชั่น</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  methods: {
    opentoast(type, massage) {
      this.$toast[type](massage, {
        position: "top-right",
      });
    },

    async onGetPromotion(item, index) {
      console.log(index);
      if (index == 3) {
        //รับเครดิตเงินคืน 0.3%
        this.$router.push({ path: "/cashback" });
        return 1;
      } else if (index == 4) {
        this.$router.push({ path: "/invite" }); //ชวนรเพื่อน
        return 1;
      } else if (index == 5 || index == 6) {
        window.open("http://line.me/ti/p/~@bbgame88", "_blank"); //เล่นเสียโปรวันเกิด
        return 1;
      }

      let body = {
        bonusId: item.pm_type,
      };
      this.isLoadding = true;
      let res = await this.$store.dispatch("promotion/getPromotion", body);
      this.isLoadding = false;

      if (res.msg) {
        this.opentoast("success", res.response.message);
      } else {
        this.opentoast("error", res.response.message);
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
    }),
  },
  data: () => ({
    txtMessage: null,
    isLoadding: false,
    dataPromotion: [
      {
        pm_type: 4,
        img: require("../../assets/S__98631690.jpg"),
        title: "ฝาก 29 รับ 100 !!",
        detail: [
          {
            text: "รับได้ 1 ครั้งต่อยูสเท่านั้น",
          },
          {
            text: "ฝาก 29 รับทันที 100 บาท",
          },
          {
            text: "ทำเทิร์น 3 เท่า ถอนได้ 100",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 1,
        img: require("../../assets/S__98631693.jpg"),
        title: "สมาชิกใหม่ ฟรีโบนัส 60%",
        detail: [
          {
            text: "ฝากเงินคร้ังแรกขั้นต่ำ 100 บาท",
          },
          {
            text: "โบนัสสูงสุดไม่เกิน 500 บาท",
          },
          {
            text: "ทำเทิร์น 30 เท่า จ่ายสูงสุด 3,000 บาท",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 2,
        img: require("../../assets/S__98631692.jpg"),
        title: "ฝากครั้งแรกของวัน ฟรี! 10%",
        detail: [
          {
            text: "รับได้ 1 ครั้งต่อวัน ไม่มีขั้นต่ำ",
          },
          {
            text: "โบนัสสูงสุดไม่เกิน 500 บาท",
          },
          {
            text: "ทำเทิร์น 30 เท่า จ่ายสูงสุด 6,000 บาท",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/S__98631688.jpg"),
        title: "รับเครดิตเงินคืน 0.3%",
        detail: [
          {
            text: "เช่น มียอดเล่น 100,000 บาท ได้ทันที 300 บาท",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "นับยอดเล่นจากทั้งสล็อตและคาสิโน",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/S__98631689.jpg"),
        title: "ชวนเพื่อน รับ 0.7% ทุกยอดเล่น",
        detail: [
          {
            text: "หารายได้เสริม รับโบนัสได้ตลอดชีพ",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "ได้โบนัสสูงสุดถึง 10 ขั้นสมาชิก",
          },
          {
            text: "หากพบการกระทำส่อทุจริต ปั๊มเทิร์น ใช้โปรแกรมช่วยเล่น หรือพบการทำเป็นขบวนการ ทีมงานจะระงับบัญชีทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/S__98631686.jpg"),
        title: "แทงถูกหรือแทงผิด 10 ตาติด",
        detail: [
          {
            text: "แทงถูกหรือแทงผิด 10 ตาติด เฉพาะคาสิโน",
          },
          {
            text: "จะได้โบนัสตามไม้ที่แทงน้อยที่สุด สูงสุด 5,000 บาท",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
          {
            text: "หากตรวจพบว่ามีการแทงสวนกันจะถูกตัดสิทธิ์ทันที",
          },
        ],
      },
      {
        pm_type: 0,
        img: require("../../assets/S__98631691.jpg"),
        title: "โปรวันเกิด แจกฟรี! 500 บาท",
        detail: [
          {
            text: "ต้องเป็นสมาชิก 1 เดือนขึ้นไป",
          },
          {
            text: "ต้องมีประวัติการเล่น 1,000 บาทขึ้นไป",
          },
          {
            text: "แสดงบัตรประชาชนวันเกิด รับทันที 500 บาท",
          },
          {
            text: "ถอนเป็นเงินสดได้ทันที ไม่ต้องทำเทิร์น",
          },
        ],
      },
    ],
  }),
};
</script>
<style scoped>
.btn-login-text {
  font-size: 1.2em;

  letter-spacing: 0.2px;
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.text-title {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-card {
  border-radius: 8px;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
.img-promotion {
  height: 100%;
  width: 100%;
}

.text-promotion-detail {
  font-size: 1rem;
  font-weight: 400;
}
</style>
import config from "../../config/config";
import { globalAxios } from "../../../axios-config";
const state = () => ({
  userInvite: [],
});

const mutations = {
  setUserInvite(state, payload) {
    state.userInvite = payload;
  },
};

const actions = {
  async userGetInvite({ commit }) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.invite}`,
      });
    //  console.log(response, "Response Invite");
      commit("setUserInvite", response.data);
      return response.data;
    } catch (error) {
   //   console.log("error", error);
      return error.response.data.errors;
    }
  },
  async getBonusInvite({ commit }) {
    try {
      let response = await globalAxios({
        method: "post",
        url: `/${config.api.invite}/bonus`,
      });
      return { msg: true, data: response };
    } catch (error) {
      return { msg: false, data: error.response };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<template>
  <div style="background: black">
    <div class="header">
      <v-container style="height: 100%">
        <v-row style="height: 100%">
          <!-- <Snow /> -->
          <ListMenu />
          <v-col
            style="height: calc(100% - 109.6px)"
            class="
              d-flex
              align-xl-end align-lg-end
              justify-lg-center
              align-center
              flex-column
            "
            cols="12"
          >
            <v-col
              cols="12"
              sm="10"
              md="10"
              lg="6"
              xl="6
          "
              class="
                d-flex
                flex-column
                justify-lg-end
                align-lg-end align-xl-end align-md-center align-sm-center
                justify-xl-center
                justify-md-center
                justify-sm-center
                justify-center
                align-center
                login-box
              "
            >
              <div class="d-flex flex-column align-center resize-logo-img">
                <div
                  :style="$vuetify.breakpoint.xl ? 'height:140px' : ''"
                ></div>
                <v-img
                  transition="slide-x-transition"
                  class="main-logo bounce"
                  aspect-ratio="1.4"
                  contain
                  src="../assets/BIRDBET88.webp"
                  width="40% 
                  "
                >
                </v-img>
                <v-col class="pt-5" cols="12" sm="11">
                  <div v-if="userProfile.length == 0">
                    <v-row>
                      <v-col cols="12" sm="7" md="7" lg="7" xl="7">
                        <v-row>
                          <v-col
                            cols="2"
                            class="
                              d-flex
                              mt-1
                              mb-1
                              my-xl-1 my-lg-1 my-md-1 my-sm-1
                              form-icon-style
                              justify-center
                              align-center
                            "
                          >
                            <v-icon
                              :size="$vuetify.breakpoint.xl ? '30px' : ''"
                              color="#5B5E6A"
                              >mdi-account-circle</v-icon
                            >
                          </v-col>
                          <v-col
                            cols="10"
                            class="
                              px-0
                              pb-1
                              pt-1
                              mt-xl-0
                              py-xl-1 py-lg-1
                              mt-md-0
                              py-md-1
                              mt-sm-0
                              py-sm-1
                            "
                          >
                            <v-text-field
                              type="number"
                              class="v-label-custom"
                              :height="$vuetify.breakpoint.xl ? '65px' : ''"
                              hide-details
                              label="เบอร์โทรศัพท์"
                              flat
                              solo
                              v-model="userTelNumber"
                              single-line
                              style="
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                              "
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="2"
                            class="
                              d-flex
                              mt-1
                              mb-3
                              my-xl-1 my-lg-1 my-md-1 my-sm-1
                              form-icon-style
                              justify-center
                              align-center
                            "
                          >
                            <v-icon
                              :size="$vuetify.breakpoint.xl ? '30px' : ''"
                              color="#5B5E6A"
                              >mdi-key-variant</v-icon
                            >
                          </v-col>
                          <v-col
                            cols="10"
                            class="
                              px-0
                              pt-1
                              mt-xl-0
                              py-xl-1 py-lg-1
                              mt-md-0
                              py-md-1
                              mt-sm-0
                              py-sm-1
                            "
                          >
                            <v-text-field
                              class="v-label-custom"
                              type="password"
                              hide-details
                              v-model="passWord"
                              label="รหัสผ่าน"
                              :height="$vuetify.breakpoint.xl ? '65px' : ''"
                              flat
                              solo
                              style="
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                              "
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                        <v-row>
                          <v-col
                            class="
                              pr-1
                              pa-0
                              py-lg-1 py-xl-1 py-md-1 py-sm-1
                              pa-sm-3
                            "
                            cols="6"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                          >
                            <v-btn
                              :height="$vuetify.breakpoint.xl ? '65px' : '48px'"
                              class="btn-login"
                              block
                              depressed
                              @click="onLogin()"
                            >
                              <img
                                img
                                src="../assets/enter.png"
                                :width="
                                  $vuetify.breakpoint.xl ? '30px' : '20px'
                                "
                              />
                              <div class="btn-login-text pl-2">เข้าสู่ระบบ</div>
                            </v-btn>
                          </v-col>
                          <v-col
                            class="
                              pl-1
                              pa-0
                              py-lg-1 py-xl-1 py-md-1 py-sm-1
                              pa-sm-3
                            "
                            cols="6"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                          >
                            <v-btn
                              :height="$vuetify.breakpoint.xl ? '65px' : '48px'"
                              class="btn-login"
                              block
                              depressed
                              @click.stop="showDialog = true"
                            >
                              <img
                                img
                                src="../assets/add-user.png"
                                :width="
                                  $vuetify.breakpoint.xl ? '30px' : '20px'
                                "
                              />
                              <div class="btn-login-text pl-2">สมัครสมาชิก</div>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <RegisterDialog
                        :dialog="showDialog"
                        @onCancelDialog="onCancelDialog($event)"
                      />
                    </v-row>
                  </div>
                  <div v-else class="border-card">
                    <v-row>
                      <v-col cols="12" sm="8" lg="8" lx="8" md="8">
                        <div
                          v-if="$vuetify.breakpoint.name != 'xs'"
                          class="pb-1 text-start"
                        >
                          <v-row>
                            <v-col class="d-flex">
                              <v-icon
                                class="pr-1"
                                :size="$vuetify.breakpoint.xl ? '20px' : ''"
                                color="#fff"
                                >mdi-account-circle</v-icon
                              >
                              ข้อมูลผู้ใช้
                            </v-col>
                          </v-row>
                        </div>
                        <div
                          class="
                            border-card-detail
                            text-start
                            pt-1
                            pl-2
                            pb-1
                            mt-1
                          "
                        >
                          <h5 style="color: rgba(246, 197, 45, 1)">
                            ชื่อ-สกุล :<span style="color: white">
                              {{ userProfile.firstname }}
                              {{ userProfile.lastname }}</span
                            >
                          </h5>
                        </div>
                        <div
                          class="
                            border-card-detail
                            text-start
                            pt-1
                            pl-2
                            pb-1
                            mt-1
                          "
                        >
                          <h5 style="color: rgba(246, 197, 45, 1)">
                            ยูสเซอร์เนม :
                            <span style="color: white">{{
                              userProfile.username
                            }}</span>
                          </h5>
                        </div>
                        <div
                          class="
                            border-card-detail
                            text-start
                            pt-1
                            pl-2
                            pb-1
                            mt-1
                          "
                        >
                          <h5 style="color: rgba(246, 197, 45, 1)">
                            ไอดีไลน์ :
                            <span style="color: white">{{
                              userProfile.lineId
                            }}</span>
                          </h5>
                        </div>
                        <div class="pt-2">
                          <v-row>
                            <v-col cols="6 pr-1">
                              <v-btn
                                :height="
                                  $vuetify.breakpoint.xl ? '48px' : '35px'
                                "
                                class="btn-login"
                                block
                                depressed
                                @click="$router.push({ path: '/game' })"
                              >
                                <div>เข้าคาสิโน</div>
                              </v-btn></v-col
                            ><v-col cols="6 pl-1">
                              <v-btn
                                :height="
                                  $vuetify.breakpoint.xl ? '48px' : '35px'
                                "
                                class="btn-login"
                                block
                                depressed
                                @click.stop="showDialogConfirm = true"
                              >
                                <div>ออกจากระบบ</div>
                              </v-btn></v-col
                            ></v-row
                          >
                        </div>
                      </v-col>
                      <v-col
                        class="pt-0 pb-2"
                        cols="12"
                        sm="4"
                        lg="4"
                        lx="4"
                        md="4"
                      >
                        <div
                          v-if="$vuetify.breakpoint.name != 'xs'"
                          style="height: 100%"
                        >
                          <div class="border-card-detail" style="height: 55%">
                            <v-col
                              style="height: 100%"
                              class="
                                d-flex
                                flex-column
                                justify-center
                                align-center
                              "
                              ><h4 style="color: rgba(246, 197, 45, 1)">
                                เครดิตของคุณ
                              </h4>
                              <h4>{{ userBalance.balance }}</h4></v-col
                            >
                          </div>
                          <div
                            class="border-card-detail mt-1"
                            style="height: 45%"
                          >
                            <v-col
                              class="
                                d-flex
                                flex-column
                                justify-center
                                align-center
                              "
                              style="height: 100%"
                              ><h4 style="color: rgba(246, 197, 45, 1)">
                                ยอดเทิร์น
                              </h4>
                              <h4>
                                {{ userBalance.currentTurnover }}/{{
                                  userBalance.targetTurnover
                                }}
                              </h4>
                            </v-col>
                          </div>
                        </div>

                        <div v-else>
                          <div class="border-card-detail py-1">
                            <v-row>
                              <v-col
                                cols="12"
                                class="d-flex align-center justify-center pb-0"
                              >
                                <v-icon color="white" class="pa-0"
                                  >mdi-cash</v-icon
                                >
                                เครดิต: {{ userBalance.balance }}
                              </v-col>
                              <v-col
                                cols="12"
                                class="d-flex align-center justify-center pt-0"
                              >
                                ฿ ยอดเทิร์น:
                                {{ userBalance.currentTurnover }}/{{
                                  userBalance.targetTurnover
                                }}
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-col>
                      <ConfirmDialog
                        :dataConfirmDialog="dataConfirmDialog"
                        :onShowDialog="showDialogConfirm"
                        @onCancelConfirmDialog="onCancelConfirmDialog($event)"
                      />
                    </v-row>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "../components/ConfirmDialog";
import { mapState } from "vuex";
import Snow from "./Snow";
import Alert from "./Alert";
import ListMenu from "./ListMenu";
import RegisterDialog from "./RegisterDialog";
export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      userBalance: (state) => state.user.userBalance,
    }),
  },
  data: () => ({
    refId: null,
    dataConfirmDialog: { title: "ต้องการออกจากระบบใช่หรือไม่?" },
    showDialogConfirm: false,
    userTelNumber: null,
    passWord: null,
    showDialog: false,
    alert: {
      text: "",
      onAlert: false,
    },
    loading: false,
  }),
  components: {
    ConfirmDialog,
    Alert,
    Snow,
    ListMenu,
    RegisterDialog,
  },
  methods: {
    opentoast(massage) {
      this.$toast.error(massage, {
        position: "top-right",
      });
    },
    onCancelConfirmDialog(onCancelConfirmDialog) {
      this.showDialogConfirm = onCancelConfirmDialog.isOpen;
      if (onCancelConfirmDialog.onAccept == true) {
        this.onLogOut();
      }
    },

    onCancelDialog(onCancelDialog) {
      this.showDialog = onCancelDialog;
      // console.log("OnCancelDialog", onCancelDialog);
    },
    async onLogin() {
      this.loading = true;
      //  console.log(this.loading);
      let body = {
        username: this.userTelNumber,
        password: this.passWord,
      };
      //  console.log("dasdadadadasdssad", body);
      let res = await this.$store.dispatch("user/userAuthen", body);
      //  console.log(res);
      if (res === true) {
        this.loading = false;
        //   console.log(this.loading);
        this.getBalance();
      } else {
        this.opentoast(res);
        // this.alert.onAlert = true;
        // this.alert.text = res;
        this.loading = false;
        //  console.log(this.loading);
      }
    },
    onLogOut() {
      localStorage.removeItem("toKen");
      this.$store.commit("user/clearUserDataWhenLogout");
      this.$router.push("/").catch(() => {});
    },
    async getBalance() {
      if (localStorage.getItem("toKen") != null) {
        let res = await this.$store.dispatch("user/userGetBalance");
        if (res) {
          //  console.log("getbalance");
          setTimeout(() => this.getBalance(), 3000);
        }
      }
    },
  },
  mounted() {
    if (localStorage.getItem("toKen")) {
      this.$store.commit("user/getCurentUser");
    }
    if (!window.getBalancing) {
      this.getBalance();
    }
    this.$nextTick(() => {
      this.waitRender = true;
    });
  },
};
</script>

<style scoped>
.border-card {
  border-radius: 8px;
  opacity: 0.98;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-image: linear-gradient(
    to bottom right,
    #321a04 0% 65%,
    #141414 95% 100%
  );
}
.border-card-detail {
  border-radius: 8px;
  opacity: 0.98;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #050505 !important;
}

@media only screen and (min-width: 1264px) and (max-width: 1372px) {
  .header {
    background: url("../assets/cover.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 784px !important;
  }
}
@media only screen and (min-width: 1373px) and (max-width: 1439px) {
  .header {
    background: url("../assets/cover.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 823px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1535px) {
  .header {
    background: url("../assets/cover.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 876px !important;
  }
}
@media only screen and (min-width: 1536px) and (max-width: 1636px) {
  .header {
    background: url("../assets/cover.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 933px !important;
  }
}
@media only screen and (min-width: 1637px) and (max-width: 1736px) {
  .header {
    background: url("../assets/cover.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 990px !important;
  }
}
@media only screen and (max-width: 1263px) {
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1700px !important;
    background-position-y: 450px !important;
  }
}

@media only screen and (max-width: 1160px) {
  .header {
    height: 1500px !important;
    background-position-y: 400px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .header {
    height: 1140px !important;
    background-position-y: 147px !important;
  }
}
@media only screen and (max-width: 961px) {
  .header {
    height: 1319px !important;
    background-position-y: 350px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  /* .login-box {
    padding: 0px 0px 0px 0px !important;
    margin-top: -460px !important;
    width: 90% !important;
  } */
  /* .resize-logo-section {
    margin-top: -80px !important;
    padding-top: 0px !important;
  } */
  /* .resize-logo-img {
    padding-top: 0px;
    width: 72% !important;
  } */
  /* .text-slot9th {
    margin-top: -180px;
  } */
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 841px !important;
    background-position-y: 53px !important;
  }
  /* .form-text-header {
    font-size: 25px !important;
  } */
  /* .form-text-detail {
    font-size: 15px !important;
  } */
  /* .size-icon-correc {
    width: 25px !important;
  } */
}
@media only screen and (min-width: 769px) and (max-width: 960px) {
  /* .login-box {
    padding: 0px 0px 0px 0px !important;
    margin-top: -460px !important;
    width: 90% !important;
  }
  .resize-logo-section {
    margin-top: -80px !important;
    padding-top: 0px !important;
  } */
  /* .resize-logo-img {
     padding-top: 60px !important;
    width: 80% !important;
  } */
  .text-slot9th {
    margin-top: -180px;
  }
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 1180px !important;
    background-position-y: 400px !important;
  }
  .form-text-header {
    font-size: 25px !important;
  }
  .form-text-detail {
    font-size: 15px !important;
  }
  .size-icon-correc {
    width: 25px !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-icon-size {
    font-size: 20px !important;
  }
  .btn-login-text {
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
  .login-box {
    padding: 0px 0px 0px 0px !important;
    /* margin-top: -385px !important; */
    width: 100% !important;
  }
  .resize-logo-section {
    /* margin-top: -70px !important; */
    padding-top: 0px !important;
  }
  .resize-logo-img {
    padding-top: 79px !important;
    width: 100% !important;
  }
  .text-slot9th {
    /* margin-top: -180px; */
  }
  .header {
    background: url("../assets/cover-mb.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    height: 491px !important;
    background-position-y: 60px !important;
  }
  .form-text-header {
    font-size: 24px !important;
  }
  .form-text-detail {
    font-size: 11px !important;
  }
  .size-icon-correc {
    width: 20px !important;
  }
  .btn-line-regis {
    width: 120px !important;
  }
}
@media only screen and (max-width: 414px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 433px !important;
    background-position-y: 53px !important;
  }
}
@media only screen and (max-width: 375px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 433px !important;
    background-position-y: 53px !important;
  }
}
@media only screen and (max-width: 320px) {
  .form-text-header {
    font-size: 23px !important;
  }
  .form-text-detail {
    font-size: 13px !important;
  }
  .header {
    height: 376px !important;
    background-position-y: 53px !important;
  }
}
.header {
  /* display: flex;
  justify-content: flex-end; */
  width: 100%;
  height: 1080px;
  background: url("../assets/cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.btn-login {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
}
.btn-login-text {
  font-size: 1.5em;

  letter-spacing: 0.2px;
}
.form-icon-style {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(232, 232, 216, 1) 8%,
    rgba(236, 231, 217, 1) 11%,
    rgba(187, 179, 165, 1) 91%
  );
}
.resize-logo-img {
  width: 100%;
  height: 100%;
}
.resize-logo-section {
  padding-top: 40px;
  width: 100%;
  height: 100%;
}
/* .login-box {
  padding: 64px 40px 0px 0px;
} */
.form-text-header {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}
.form-text-detail {
  font-size: 28px;
  color: #fff;
}
.size-icon-correc {
  width: 40px;
}
.btn-line-regis {
  width: 300px;
}
.main-logo {
  animation: shake 8s;
  animation-iteration-count: infinite;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
</style>

import config from "../../config/config";
import { globalAxios } from '../../../axios-config'
const state = () => ({
  cashback: [],
  cashback2: [],
  invite: []
});

const mutations = {
  setDataCashBack(state, payload) {
    state.cashback = payload
  },
  setDataCashBack2(state, payload) {
    state.cashback2 = payload
  },
  setDataInvite(state, payload) {
    state.invite = payload
  }
};

const actions = {
  async getRankingCashBack({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/cashback/${body.month}/${body.year}`,
      });
    //  console.log(response, 'sssss')
      commit('setDataCashBack', response.data.datas);
      return response.data;
    } catch (error) {
    //  console.log("error", error);
      return error.response.data.errors;
    }
  },
  async getRankingCashBack2({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/cashback2/${body.month}/${body.year}`,
      });
    //  console.log(response, 'tttttt')
      commit('setDataCashBack2', response.data.datas);
      return response.data;
    } catch (error) {
  //    console.log("error", error);
      return error.response.data.errors;
    }
  },
  async getRankinginvite({ commit }, body) {
    try {
      let response = await globalAxios({
        method: "get",
        url: `/${config.api.ranking}/invite/${body.month}/${body.year}`,
      });
   //   console.log(response, 'xxxxxxx')
      commit('setDataInvite', response.data.datas);
      return response.data;
    } catch (error) {
   //   console.log("error", error);
      return error.response.data.errors;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-12"},[_c('v-container',{staticClass:"pb-16 px-lx-16 px-lg-16"},[_c('div',{staticClass:"text-title text-center"},[_c('h2',[_vm._v("รางวัลประจำเดือน")])]),_c('div',{staticClass:"divider-custom",staticStyle:{"height":"2px"}}),_c('v-row',{staticClass:"mt-4 justify-center"},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"10","sm":"10","md":"5","xl":"5","lg":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"hide-details":"","flat":"","items":_vm.months,"item-text":'month',"item-value":'monthId',"label":"Solo field","solo":""},on:{"change":function($event){return _vm.getAllRanking()}},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hide-details":"","flat":"","items":_vm.years,"item-text":'year',"item-value":'value',"solo":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1)],1),_c('v-col',{staticClass:"border-card mb-4 text-center",attrs:{"cols":"11","lg":"10","xl":"10","md":"9","sm":"8"}},[_vm._v(" 10 อันดับ ผู้ที่ได้รับเงินคืนสูงที่สุด "),_c('div',{staticClass:"black mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dark":"","loading":_vm.isLoading,"headers":_vm.headersCashBack,"items":_vm.cashback,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.cashback.indexOf(item) == 0
                    ? 'no1'
                    : _vm.cashback.indexOf(item) == 1
                    ? 'no2'
                    : _vm.cashback.indexOf(item) == 2
                    ? 'no3'
                    : ''},[_c('td',[_vm._v(" "+_vm._s(_vm.cashback.indexOf(item) + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.username ? item.username : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.amount ? item.amount.toLocaleString() : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.reward)+" ")])])]}}])})],1)]),_c('v-col',{staticClass:"border-card mb-4 text-center",attrs:{"cols":"11","lg":"10","xl":"10","md":"9","sm":"8"}},[_vm._v(" 10 อันดับ ผู้ที่มียอดเสียสูงที่สุด "),_c('div',{staticClass:"black mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dark":"","loading":_vm.isLoading,"headers":_vm.headersCashBack2,"items":_vm.cashback2,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('tr',{class:_vm.cashback2.indexOf(item) == 0
                    ? 'no1'
                    : _vm.cashback2.indexOf(item) == 1
                    ? 'no2'
                    : _vm.cashback2.indexOf(item) == 2
                    ? 'no3'
                    : ''},[_c('td',[_vm._v(" "+_vm._s(_vm.cashback2.indexOf(item) + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.username ? item.username : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.amount ? item.amount.toLocaleString() : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.reward)+" ")])])]}}])})],1)]),_c('v-col',{staticClass:"border-card mb-4 text-center",attrs:{"cols":"11","lg":"10","xl":"10","md":"9","sm":"8"}},[_vm._v(" 10 อันดับ ผู้ที่ได้โบนัสชวนเพื่อนสูงที่สุด "),_c('div',{staticClass:"black mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dark":"","loading":_vm.isLoading,"headers":_vm.headersInvite,"items":_vm.invite,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('tr',{class:_vm.invite.indexOf(item) == 0
                    ? 'no1'
                    : _vm.invite.indexOf(item) == 1
                    ? 'no2'
                    : _vm.invite.indexOf(item) == 2
                    ? 'no3'
                    : ''},[_c('td',[_vm._v(" "+_vm._s(_vm.invite.indexOf(item) + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.username ? item.username : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.amount ? item.amount.toLocaleString() : "-")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.reward)+" ")])])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-row justify="center">
      <v-dialog
        max-width="600"
        @click:outside="onCancelErrorDialog()"
        v-model="onShowErrorDialog"
      >
        <v-card class="border-card">
          <div style="position: absolute; right: 3px; top: 3px; z-index: 5">
            <v-btn
              @click="onCancelErrorDialog()"
              class="ma-2"
              outlined
              x-small
              fab
              color="red"
            >
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </div>
          <v-card-title class="headline justify-center">
            <v-row class="justify-center">
              <v-col cols="12" class="d-flex justify-center pa-0">
                <v-icon size="65" color="red">mdi-alert-circle-outline</v-icon>
              </v-col>
              <v-col
                style="color: gold"
                cols="12"
                class="pb-0 d-flex flex-column justify-center align-center"
                >{{ dataError.message }}</v-col
              >
            </v-row>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-container>
              <v-row>
                <v-col
                  v-for="(item, index) in dataError.errors"
                  :key="index"
                  cols="12"
                  align="start"
                >
                  <ul>
                    <li class="content-text">
                      <div class="text-start">{{ item.message }}</div>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-btn
                width="100%"
                class="btn-signin mt-5"
                depressed
                @click="onCancelErrorDialog()"
              >
                <div class="btn-login-text pl-3">ตกลง</div>
              </v-btn>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["onShowErrorDialog", "dataError"],
  methods: {
    onCancelErrorDialog() {
      this.$emit("onCancelDialog", false);
    },
  },
};
</script>
<style scoped>
.border-card {
  border-radius: 8px !important;

  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}

.content-text {
  color: rgb(255, 0, 0);
}
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
</style>


<template>
  <div>
    <v-row class="justify-center pt-4">
      <v-col
        v-for="(item, index) in allGame"
        :key="index"
        cols="11"
        sm="5"
        lg="5"
        md="5"
      >
        <div @click="currentClick = index" class="container-custom">
          <v-img
            class="image"
            :src="`${configGlobal.prod.api}/${item.image}`"
          ></v-img>
          <div v-if="currentClick == index" class="middle">
            <button
              @click="onPlayGame(item, 'demo', null, null)"
              :loading="loadingGame"
              class="raise"
            >
              ทดลองเล่น
            </button>
            <button
              @click="
                onPlayGame(
                  item,
                  'play',
                  userToken != null ? userToken : null,
                  null
                )
              "
              v-if="userProfile.length != 0"
              class="raise"
            >
              เล่นเลย
            </button>
          </div>
        </div>
      </v-col>
    </v-row>
    <GameSubDialog
      v-if="selectedSubgame"
      :dialog="showSubGameDialog"
      @onCancelDialog="onCancelDialog($event)"
      @onSelectGame="onSelectGame($event)"
    />
  </div>
</template>
<script>
import GameSubDialog from "../components/GameSubDialog";
import { mapState } from "vuex";
import config from "../config/config";
export default {
  components: {
    GameSubDialog,
  },

  mounted() {
    this.getAllGame();
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.user.userProfile,
      allGame: (state) => state.game.allGame,
      userToken: (state) => state.user.userToken,
    }),
    configGlobal() {
      return config;
    },
  },
  data: () => ({
    currentClick: null,
    selectedSubgame: false,
    currentGameSelected: {},
    loadingGame: false,
    showSubGameDialog: false,
    data_game: [
      {
        title: "game1",
        gameTag: "sa",
        img: require("../assets/sa.098389ff.png"),
      },
      {
        title: "game2",
        gameTag: "wm",
        img: require("../assets/wm.cec99433.png"),
      },
      {
        title: "game3",
        gameTag: "dg",
        img: require("../assets/dg.4290471b.png"),
      },
      {
        title: "game4",
        gameTag: "sexy",

        img: require("../assets/sexy.6941d11a.png"),
      },
      {
        title: "game5",
        gameTag: "amb",
        img: require("../assets/amb.6819f11b.png"),
      },
      {
        title: "game6",
        gameTag: "sp",

        img: require("../assets/sp.d56e503f.png"),
      },
      {
        title: "game7",
        gameTag: "pretty",
        img: require("../assets/pretty.9b07a22c.png"),
      },
      {
        title: "game8",
        gameTag: "pg2",
        subgame: true,
        img: require("../assets/pg.01cc9d85.png"),
      },
      {
        title: "game9",
        gameTag: "joker",
        subgame: true,

        img: require("../assets/joker.ae7b74d2.png"),
      },
      {
        title: "game10",
        gameTag: "hotg",
        img: require("../assets/hotgraph.ff1d4e47.png"),
      },
      {
        title: "game11",
        gameTag: "ka",
        subgame: true,
        img: require("../assets/ka.90dffedd.png"),
      },
    ],
  }),
  methods: {
    onSelectGame(game) {
      // console.log("propgame", game);
      this.currentGameSelected.game.subGame = false; //เงื่อนไขเลือกเกมทำให้ false เพื่อข้าม
      this.onPlayGame(
        this.currentGameSelected.game,
        this.currentGameSelected.gameType,
        this.currentGameSelected.token,
        game.gameCode
      );
    },
    onCancelDialog(onCancelDialog) {
      this.showSubGameDialog = onCancelDialog;
      this.selectedSubgame = false;
      this.$store.commit("game/resetSubGameList");
      //  console.log("OnCancelDialog", onCancelDialog);
    },

    async getAllGame() {
      let res = await this.$store.dispatch("game/getGame");
      //  console.log(res, "res Allgame");
    },

    async onPlayGame(game, gameType, token, subGame) {
      this.currentGameSelected.game = JSON.parse(JSON.stringify(game));
      this.currentGameSelected.gameType = JSON.parse(JSON.stringify(gameType));
      this.currentGameSelected.token = JSON.parse(JSON.stringify(token));
      //  console.log("ssssssssssssss", this.currentGameSelected);
      if (game.subGame == true || game.subGame == 1) {
        //เงื่อนไขเลือกเกม
        this.loadingGame = true;
        //   console.log(this.loadingGame);
        let res = await this.$store.dispatch(
          "game/getGameSubList",
          game.gameId
        );
        this.loadingGame = false;
        if (res === true) {
          this.showSubGameDialog = true;
          this.selectedSubgame = true;
        }
        return;
      }
      if (window.isGameWorking) {
        window.isGameWorking.close();
      }

      let routeData = this.$router.resolve({
        name: "PlayGame",
        params: {
          gameTag: game.gameId,
          gameType: gameType,
          subGame: subGame == null ? "noSubgame" : subGame,
          token: token == null ? "noToken" : token,
        },
        query: { openExternalBrowser: 1 },
      });

      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style  scoped>
.cursor-hover:hover {
  cursor: pointer;
  opacity: 0.7;
}
.text-demo {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}
.container-custom:hover .image {
  opacity: 0.6;
}

.container-custom .middle {
  opacity: 1;
}

.image {
  cursor: pointer;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.container-custom {
  position: relative;
  width: 100%;
}

.text {
  background-color: #d1be11;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}
.raise {
  --color: #fcf6ba;
  --hover: #bf953f;
}
button {
  color: var(--color);
  transition: 0.45s;
}
button:hover,
button:focus {
  border-color: var(--hover);
  color: rgb(37, 37, 37);
}
button {
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(128, 94, 23, 1) 0%,
    rgba(246, 197, 45, 1) 30%,
    70%,
    rgba(128, 94, 23, 1) 100%
  );
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.25em;
  padding: 1em 2em;
  width: 150px;
}
@media only screen and (min-width: 960px) and (max-width: 1264px) {
  button {
    height: 40px !important ;
    line-height: 0 !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  button {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  button {
    height: 35px !important ;
    line-height: 0 !important;
    border: 1px solid !important;
  }
}
</style>
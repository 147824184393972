<template>
  <div class="black pb-12">
    <v-container class="pb-16 px-lx-16 px-lg-16">
      <div class="text-title text-center"><h2>รับเครดิตเงินคืน</h2></div>
      <div class="divider-custom" style="height: 2px"></div>
      <v-row class="pt-6 px-lx-16 px-lg-16 justify-center">
        <v-col
          cols="11"
          lg="10"
          xl="10"
          md="9"
          sm="8"
          class="border-card-detail"
        >
          <v-row>
            <v-col>
              <div class="mt-3 text-center" style="font-size: 20px">
                ประวัติการเล่น 7 วันล่าสุด
              </div>
              <v-container>
                <div class="black mt-4">
                  <v-data-table
                    dark
                    :loading="isLoading"
                    :headers="headersCashBack"
                    :items="userCashBack.datas"
                    class="elevation-1"
                    mobile-breakpoint="0"
                  >
                  </v-data-table></div
              ></v-container>

              <v-container>
                <div class="text-center" style="font-size: 18px">
                  ยอดเงินคืนสะสม
                  <span style="color: gold"
                    >฿{{ userCashBack.availableAmount }}</span
                  >
                </div></v-container
              >
              <v-container>
                <v-btn
                  @click="onGetBonus()"
                  width="100%"
                  class="btn-signin"
                  depressed
                >
                  <div style="color: white" class="pl-3">
                    <v-icon small class="pr-1">mdi-share-all-outline</v-icon>
                    รับเครดิตเงินคืน
                  </div>
                </v-btn></v-container
              >
              <v-container class="pb-8">
                <div class="text-center" style="font-size: 18px">
                  ขั้นต่ำ ฿{{ userCashBack.minimumAmount }} ไม่ต้องทำเทิร์น
                </div>
              </v-container>
              <ErrorDialog
                :dataError="dataError"
                :onShowErrorDialog="showDialog"
                @onCancelDialog="onCancelErrorDialog($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ErrorDialog from "../../components/ErrorDialog";
import { mapState } from "vuex";
export default {
  components: {
    ErrorDialog,
  },
  methods: {
    onCancelErrorDialog(onCancelErrorDialog) {
      // this.showDialog =[{onShowDialog:false},];
      this.showDialog = onCancelErrorDialog;
    //  console.log("onCancelErrorDialog", onCancelErrorDialog);
    },
    async getUserCashBack() {
      this.isLoading = true;
      await this.$store.dispatch("cashback/getUserCashBack");

      this.isLoading = false;
    },
    async onGetBonus() {
      let res = await this.$store.dispatch("cashback/getCashBack");
      if (res.msg) {
        this.showDialog = true;
        this.dataError = res.data;
      } else {
        this.showDialog = true;
        this.dataError = res.data;
      }
    },
  },
  mounted() {
    this.getUserCashBack();
  },
  computed: {
    ...mapState({
      userCashBack: (state) => state.cashback.userCashBack,
    }),
  },
  data: () => ({
    dataError: {},
    showDialog: false,
    isLoading: false,
    headersCashBack: [
      {
        text: "วันที่",
        align: "center",
        value: "date",
        sortable: false,
      },
      {
        text: "ยอดเล่น	",
        align: "center",
        value: "bet",
        sortable: false,
      },
      {
        text: "ยอดเงินคืน 0.3%",
        align: "center",
        value: "amount",
        sortable: false,
      },
    ],
  }),
};
</script>
<style scoped>
.btn-signin {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
.text-gold {
  font-size: 19px;
  font-weight: bold;
  background: -moz-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: -webkit-linear-gradient(
    top,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    #fceabb 0%,
    #fceabb 40%,
    #f8b500 41%,
    #f9efd4 100%
  ) !important;
}
.text-title {
  font-size: 20px;
  background: #cf0000;
  background: -webkit-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: -moz-linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  background: linear-gradient(to right, #cf0000 7%, #cfcf15 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.divider-custom {
  background: linear-gradient(
    90deg,
    rgba(255, 235, 0, 1) 0%,
    rgba(121, 9, 9, 1) 50%,
    rgba(255, 235, 0, 1) 100%
  );
}

.border-card-detail {
  border-radius: 8px;
  opacity: 0.98;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321904 !important;
  font-size: 16px;
  color: #404040;
  background-image: linear-gradient(
    to bottom right,
    #1f1f1f 0% 65%,
    #3f2105 95% 100%
  );
  background-position: center;
  background-attachment: fixed;
}
</style>
<template>
  <v-row
    ><v-col>
      <v-container>
        <div class="text-start text-promotion-header">ประวัติการเงิน</div>
        <div class="text-center">
          <v-col cols="12" class="border-card mb-4">
            ประวัติการเติมเงิน
            <div class="black mt-4">
              <v-data-table
                dark
                :loading="isLoading"
                :headers="headersDeposit"
                :items="historydeposit"
                class="elevation-1"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.number`]="{ item }">
                  <div>
                    {{ historydeposit.indexOf(item) + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :class="
                      item.status == 'success'
                        ? 'text-success'
                        : item.status == 'faild'
                        ? 'text-faild-or-cancel'
                        : item.status == 'waiting'
                        ? 'text-waitting'
                        : ''
                    "
                  >
                    {{
                      item.status == "success"
                        ? "สำเร็จ"
                        : item.status == "faild"
                        ? "ล้มเหลว"
                        : item.status == "waiting"
                        ? "กำลังดำเนินการ"
                        : ""
                    }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div>
                    {{ item.description == null ? "-" : item.description }}
                  </div>
                </template>
              </v-data-table>
            </div></v-col
          >
          <v-col cols="12" class="border-card">
            ประวัติการถอนเงิน
            <div class="black mt-4">
              <v-data-table
                dark
                :loading="isLoading"
                :headers="headersWithdraw"
                class="elevation-1"
                :items="historywithdraw"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.number`]="{ item }">
                  <div>
                    {{ historywithdraw.indexOf(item) + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :class="
                      item.status == 'success'
                        ? 'text-success'
                        : item.status == 'faild'
                        ? 'text-faild-or-cancel'
                        : item.status == 'waiting'
                        ? 'text-waitting'
                        : ''
                    "
                  >
                    {{
                      item.status == "success"
                        ? "สำเร็จ"
                        : item.status == "faild"
                        ? "ล้มเหลว"
                        : item.status == "waiting"
                        ? "กำลังดำเนินการ"
                        : ""
                    }}
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div>
                    {{ item.description == null ? "-" : item.description }}
                  </div>
                </template>
              </v-data-table>
            </div></v-col
          >
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    isLoading: false,
    headersWithdraw: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },

      { text: "จำนวนเงิน", align: "center", value: "amount", sortable: false },
      {
        text: "สถานะ",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "รายละเอียด",
        align: "center",
        value: "description",
        sortable: false,
      },
    ],
    headersDeposit: [
      {
        text: "ลำดับ",
        align: "center",
        value: "name",
        sortable: false,
        value: "number",
      },

      { text: "จำนวนเงิน	", align: "center", value: "amount", sortable: false },
      {
        text: "สถานะ",
        align: "center",
        value: "status",
        sortable: false,
      },
      {
        text: "รายละเอียด",
        align: "center",
        value: "description",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState({
      historywithdraw: (state) => state.transection.historywithdraw,
      historydeposit: (state) => state.transection.historydeposit,
    }),
  },
  methods: {
    async getBalance() {
      if (localStorage.getItem("toKen") != null) {
        let res = await this.$store.dispatch("user/userGetBalance");
        if (res) {
          //  console.log("getbalance");
          setTimeout(() => this.getBalance(), 3000);
        }
      }
    },
  },
};
</script>

<style  scoped>
.text-promotion-header {
  font-size: 22px;
  color: #ffc107;
}
.border-card {
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
.text-success {
  color: rgb(66, 161, 10);
}
.text-faild-or-cancel {
  color: red;
}
.text-waitting {
  color: orange;
}
</style>
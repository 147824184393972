<template>
  <div>
    <v-dialog
      max-width="600"
      v-model="dialog"
      @click:outside="closeAlertDialog()"
    >
      <div class="card-alert-screen">
        <v-row no-gutters>
          <v-col cols="12" style="text-align: center" class="pb-3">
            <v-icon style="font-size: 3em; color: red">mdi-alert-circle</v-icon>
            <div>
              <h3 style="color: #ffd700">
                {{ this.txtMessage }}
              </h3>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn class="btn_block" @click="closeAlertDialog()">ตกลง</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "txtMessage"],
  methods: {
    closeAlertDialog() {
      this.$emit("closeAlertDialog");
    },
  },
};
</script>

<style scoped>
.btn_block {
  border-radius: 8px;
  background: linear-gradient(90deg, #805e17 0%, #f6c52d 100%);
}
.card-alert-screen {
  border-radius: 8px !important;
  padding: 1rem;
  color: white !important;
  border-width: 3px;
  border-style: solid;
  border-color: rgba(246, 197, 45, 1);
  background-color: #321a04 !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-container',[_c('div',{staticClass:"text-start text-promotion-header"},[_vm._v("ประวัติการเงิน")]),_c('div',{staticClass:"text-center"},[_c('v-col',{staticClass:"border-card mb-4",attrs:{"cols":"12"}},[_vm._v(" ประวัติการเติมเงิน "),_c('div',{staticClass:"black mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dark":"","loading":_vm.isLoading,"headers":_vm.headersDeposit,"items":_vm.historydeposit,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.historydeposit.indexOf(item) + 1)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.status == 'success'
                      ? 'text-success'
                      : item.status == 'faild'
                      ? 'text-faild-or-cancel'
                      : item.status == 'waiting'
                      ? 'text-waitting'
                      : ''},[_vm._v(" "+_vm._s(item.status == "success" ? "สำเร็จ" : item.status == "faild" ? "ล้มเหลว" : item.status == "waiting" ? "กำลังดำเนินการ" : "")+" ")])]}},{key:"item.description",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.description == null ? "-" : item.description)+" ")])]}}],null,true)})],1)]),_c('v-col',{staticClass:"border-card",attrs:{"cols":"12"}},[_vm._v(" ประวัติการถอนเงิน "),_c('div',{staticClass:"black mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dark":"","loading":_vm.isLoading,"headers":_vm.headersWithdraw,"items":_vm.historywithdraw,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.historywithdraw.indexOf(item) + 1)+" ")])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c('div',{class:item.status == 'success'
                      ? 'text-success'
                      : item.status == 'faild'
                      ? 'text-faild-or-cancel'
                      : item.status == 'waiting'
                      ? 'text-waitting'
                      : ''},[_vm._v(" "+_vm._s(item.status == "success" ? "สำเร็จ" : item.status == "faild" ? "ล้มเหลว" : item.status == "waiting" ? "กำลังดำเนินการ" : "")+" ")])]}},{key:"item.description",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.description == null ? "-" : item.description)+" ")])]}}],null,true)})],1)])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }